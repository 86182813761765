import { useActions, useValues } from "kea";
import { router } from "kea-router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageContainer } from "../../components/page-container/page-container";
import { ISidebarItem, Sidebar } from "../../components/sidebar/sidebar";
import { PageRoute, routeLogic } from "../../logics/route.logic";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import { AccountPage } from "../account-page/account-page";
import { UsersPage } from "../users-page/users-page";
import { BillingPage } from "../billing-page/billing-page";
import { OrganizationPage } from "../organization-page/organization-page";
import { OverviewPage } from "../overview-page/overview-page";
import "./app-layout.scss";
import { Plan, Role } from "../../components/permissions/permissions";
import { Callout, Intent } from "@blueprintjs/core";
import { PageSection } from "../../components/page-section/page-section";
import { Grid } from "react-flexbox-grid";
import { billingLogic } from "../../logics/billing.logic";
import { appLogic } from "../../logics/app.logic";
import { PopUp } from "../../components/pop-up/pop-up";
import { IconWarning } from "../../components/icons/icons";
import { useService } from "@creately/use-service";
import { PostMessageService, PostMessageSendEventType } from "../../services/post-message.s";
import { POST_MESSAGE_SERVICE } from "../../token";
import { SettingPage } from "../setting-page/setting-page";
import { ManageAccountPage } from "../manage-account-page/manage-account-page";

export const AppLayout: React.FC<{}> = () => {
  const { push, replace } = useActions(router);
  const { setMainSection } = useActions(routeLogic);
  const { openPaymentSources } = useActions(billingLogic);

  const { planChanged } = useValues(billingLogic);
  const {
    location: { pathname },
  } = useValues(routeLogic);

  const {
    user,
    userLoading,
  }: { user: IUser; userLoading: boolean } = useValues(userLogic);

  const { track } = useActions(appLogic);

  const [activeItemId, setActiveItemId] = useState<string | undefined>();

  const [ showPaymentSuccessPopUp, setShowPaymentSuccessPopUp ] = useState<boolean>(false);

  const [ showPaymentRetryPopUp, setShowPaymentRetryPopUp ] = useState<boolean>(false);

  
  const { t } = useTranslation();

  const getSidebarItems = (): ISidebarItem[] => {
    const sidebarItems = [
      {
        id: "overview",
        title: t("overview"),
        route: PageRoute.Overview,
        disabled: false,
      },
      {
        id: "account",
        title: t("myAccount"),
        route: PageRoute.Account,
        disabled: false,
      },
      {
        id: "organization",
        title: t("organization"),
        route: PageRoute.Organization,
        disabled: !!!(
          (user?.planType === Plan.Team ||
            user?.planType === Plan.PublicTeam ||
            user?.planType === Plan.Enterprise) &&
          !!user?.organization?.id
        ),
      },
      {
        id: "users",
        title: t("users"),
        route: PageRoute.Users,
        disabled: !!!(
          user?.planType?.endsWith(Plan.Team) || user?.planType === Plan.PublicTeam || user?.planType === Plan.Enterprise
        ),
      },
      {
        id: "setting",
        title: t("orgPreferences"),
        route: PageRoute.Setting,
        disabled: !!!(
          ((user?.role === Role.SuperAdmin || user?.role === Role.Admin ) && user?.planType === Plan.Enterprise)
        ),
      },
      {
        id: "billing",
        title: t("billing"),
        route: PageRoute.Billing,
        disabled: !!!(
          (user?.role === Role.SuperAdmin && user?.planType != Plan.PublicTeam) ||
          user?.planType === Plan.Pro ||
          user?.planType === Plan.Personal ||
          user?.planType === Plan.Lite
        ),
      },
      {
        id: "manage",
        title: t("closeAccount"),
        route: PageRoute.Manage,
        disabled: !!!( user?.role === Role.SuperAdmin && user?.planType != Plan.PublicTeam &&  user?.planType != Plan.Free ),
      },
    ];
    return sidebarItems.filter((item) => !item.disabled);
  };

  useEffect(() => {
    setActiveItemId(
      getSidebarItems().find((item) => item.route === pathname)?.id
    );
  });

  const getPage = () => {
    if (pathname === PageRoute.Organization) {
      return <OrganizationPage />;
    } else if (pathname === PageRoute.Users) {
      return <UsersPage />;
    } else if (pathname === PageRoute.Account) {
      return <AccountPage />;
    } else if (pathname === PageRoute.Setting) {
      return <SettingPage />;
    } else if (pathname === PageRoute.Billing) {
      return <BillingPage
      openPaymentSources = { handleRetryClick }/>;
    } else if (pathname === PageRoute.Overview) {
      return <OverviewPage />;
    } else if (pathname === PageRoute.Manage) {
      return <ManageAccountPage />;
    } else if (pathname === PageRoute.Close) {
      return <ManageAccountPage
      currentSection = "delete" />;
    } else {
      replace(PageRoute.Overview);
      return null;
    }
  };

  const handleNavItemClick = (pageRoute: PageRoute): void => {
    if (pageRoute == PageRoute.Close || pageRoute == PageRoute.Manage) {
      setMainSection(true);
    }
    track("left.account.tab.click", {
      value1: getSidebarItems()
        .filter((item) => item.route === pageRoute)
        .map((item) => item.title)
        .toString(),
    });
    push(pageRoute);
  };

  const handleRetryClick = () => {
    if (user?.organization?.subscription?.paymentFailedCount > 0){
      openPaymentSources(handlePaymentSourcesRespons);
    } else {
      openPaymentSources();
    }
  };

  const handlePaymentSourcesRespons = (data: any) => {
    if (data && data.status === "success") {
      setShowPaymentSuccessPopUp(true);
      track("notification.warning.load", {
        value1: "Activation Successful!",
        value2: "Your account has been activated successfully. Thank you for choosing Creately."
      });
    } else {
      track("notification.error.load", {
        value1: "Sorry, Activation Failed!",
        value2: "Unfortunately, we could not process your request. Please try again or contact our support team."
      });
      setShowPaymentRetryPopUp(true);
    }
  };

  const handleClosePaymentRetryPopUp = () => {
    track("notification.error.click", {
      value1: "Retry",
    });
    setShowPaymentRetryPopUp(false);
    openPaymentSources(handlePaymentSourcesRespons);
  };

  const handleClosePaymentSuccessPopUp = () => {
    setShowPaymentSuccessPopUp(false);
    track("notification.warning.click", {
      value1: "Continue",
    });
    const postMessageService = useService<PostMessageService>(
      POST_MESSAGE_SERVICE
    );
    postMessageService.sendToParent(PostMessageSendEventType.Reload, {});
  };

  const handleOpenContactSupport = () => {
    setShowPaymentRetryPopUp(false);
    track("notification.error.click", {
      value1: "Contact Support",
    });
    window.open('https://support.creately.com');
  };

  return userLoading ? (
    LoadingIndicator
  ) : (
    <div className="app-layout">
      <Sidebar
        items={getSidebarItems()}
        onClick={handleNavItemClick}
        activeItemId={activeItemId}
      />
      <PerfectScrollbar>
        <PageContainer>
          {user?.organization?.subscription?.paymentFailedCount > 0 && (
              <div className="app-layout__warning-banner">
                <Grid fluid>
                  <PageSection>
                    <Callout intent={Intent.DANGER}>
                      <p>
                        <b>{t("pages.app-layout.paymentFailDescription1")}</b>
                        <br />
                        {t("pages.app-layout.paymentFailDescription2")}
                      </p>
                      <a
                        className={`app-layout__warning-card-btn`}
                        onClick={handleRetryClick}
                      >
                        {t("pages.app-layout.retryButton")}
                      </a>
                    </Callout>
                  </PageSection>
                </Grid>
              </div>
            )}
            {user?.organization?.subscription?.paymentFailedCount === 0 && user?.organization?.subscription?.paused && pathname != PageRoute.Close && (
              <div className="app-layout__warning-banner">
                <Grid fluid>
                  <PageSection>
                    <Callout intent={Intent.DANGER}>
                      <p>
                        <b>{t("pages.app-layout.accountPauseDescription")}</b>
                      </p>
                      <a
                        className={`app-layout__warning-card-btn`}
                        onClick={() => handleNavItemClick( PageRoute.Close )}
                      >
                        {t("pages.app-layout.resumeButton")}
                      </a>
                    </Callout>
                  </PageSection>
                </Grid>
              </div>
            )}
          {planChanged && (
            <div className="app-layout__warning-banner">
              <Grid fluid>
                <PageSection>
                  <Callout intent={Intent.PRIMARY}>
                    <p>
                      <b>
                        {t("notifications.subscriptionChanged.description")}
                      </b>
                    </p>
                  </Callout>
                </PageSection>
              </Grid>
            </div>
          )}
          {getPage()}
        </PageContainer>
      </PerfectScrollbar>
      { showPaymentSuccessPopUp && 
        <PopUp 
          title={t("notifications.paymentSuccessPopUp.title")}
          desctiption={t("notifications.paymentSuccessPopUp.description")}
          icon="tick-circle"
          iconIntent={ Intent.SUCCESS }
          closeAction = { handleClosePaymentSuccessPopUp }
          closeButtonName = "Continue"
          button1Action={()=> {}}
          closeIconAction= {()=> {}}
          closeButtonIntent= { Intent.SUCCESS }
        />
      }
      { showPaymentRetryPopUp && 
        <PopUp 
          title={t("notifications.paymentRetryPopUp.title")}
          desctiption={t("notifications.paymentRetryPopUp.description")}
          icon={IconWarning}
          iconIntent={ Intent.NONE }
          closeAction = { handleClosePaymentRetryPopUp }
          closeButtonName = "Retry"
          closeButtonIntent= { Intent.SUCCESS }
          button1Name= "Contact Support"
          button1Action = { handleOpenContactSupport }
          button1Intent = { Intent.NONE }
          showCloseIcon = { true }
          closeIconAction = {()=> { setShowPaymentRetryPopUp(false) }}
        />
      }
    </div>
  );
};
