import { injectable } from "@creately/use-service";

/**
 * Post message send event types for team portal.
 */
export enum PostMessageSendEventType {
  Open = "teamPortal:open",
  Close = "teamPortal:close",
  Reload = "teamPortal:reload",
  ShowNotification = "teamPortal:showNotification",
  HideNotification = "teamPortal:hideNotification",
  ChangeLanguage = "teamPortal:changeLanguage",
}

/**
 * Post message receive event types for team portal.
 */
export enum PostMessageReceiveEventType {
  resync = "teamPortal:resync",
}

/**
 * Post message service
 * An injectable service which uses the window post message api
 * to send and receive messages from other windows.
 */
@injectable()
export class PostMessageService {
  constructor() {}

  /**
   * Sends a message to the parent window.
   */
  public sendToParent(event: string, data: object) {
    this.sendToWindow(event, data, this.getParentWindow());
  }

  /**
   * Sends a message to the given window.
   */
  public sendToWindow(event: string, data: object, targetWindow: any) {
    try {
      const message = JSON.stringify({ source: "creately", event, data });
      targetWindow.postMessage(message, "*");
    } catch (err) {
      console.error(err);
    }
  }

  // FIXME: Implement receive method if needed

  /**
   * Returns the parent window.
   */
  private getParentWindow() {
    return window.parent;
  }
}
