import {
  Button,
  Callout,
  Card,
  Classes,
  Icon,
  Intent,
  Position,
  Radio,
  RadioGroup,
  Spinner,
  TextArea,
  Tooltip,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import { useValues, useActions } from "kea";
import { userLogic } from "../../logics/user.logic";
import {
  format,
  formatDistanceToNowStrict,
  differenceInDays,
  addMonths,
} from "date-fns";
import { IUser } from "../../models/user.i";
import { useTranslation } from "react-i18next";
import "./manage-account-page.scss";
import { appLogic } from "../../logics/app.logic";
import { billingLogic } from "../../logics/billing.logic";
import {
  Permission,
  PermissionType,
  Plan,
} from "../../components/permissions/permissions";
import { routeLogic } from "../../logics/route.logic";
import { IconInfo, IconPause, IconTeam, IconWorkspaces } from "../../components/icons/icons";
import { ISelectItem, SelectInput } from "../../components/select-input/select-input";

enum AccountClosePageSection {
  Main = "main",
  Delete = "delete",
  CancelOrPause = "cancelOrPauseSubscription",
  Cancel = "cancelSubscription",
  OfferClaimed = "offerClaimed",
  OfferExpired = "offerExpired",
  CancelStepOne = "cancelStepOne",
  CancelStepTwo = "cancelStepTwo",
  CancelWarning = "cancelWarning",
}

export const ManageAccountPage: React.FC<any> = (props) => {
  const defaultClass = "close-account-page";

  const { t } = useTranslation();

  const {
    closeCBAccount,
    deleteCBAccount,
    pauseCBAccount,
    resumeCBAccount,
    removePauseCBAccount,
    removeCloseCBAccount,
  } = useActions(billingLogic);

  const { reset } = useActions(routeLogic);

  const refContainer = useRef(null);

  const {
    closeCBAccountLoading,
    pauseCBAccountLoading,
    resumeCBAccountLoading,
    removePauseCBAccountLoading,
    removeCloseCBAccountLoading,
  }: {
    closeCBAccountLoading: boolean;
    pauseCBAccountLoading: boolean;
    resumeCBAccountLoading: boolean;
    removePauseCBAccountLoading: boolean;
    removeCloseCBAccountLoading: boolean;
  } = useValues(billingLogic);

  const { closePageMainSection }: { closePageMainSection: boolean } = useValues(
    routeLogic
  );

  const {
    user,
    userLoading,
    addExtendSubOfferLoading,
  }: {
    user: IUser;
    userLoading: boolean;
    addExtendSubOfferLoading: boolean;
  } = useValues(userLogic);

  const { track } = useActions(appLogic);

  const {
    addExtendSubOffer,
    closeUserAccount,
    claimPersonalMonPlanOffer,
  } = useActions(userLogic);

  const [currentSection, setCurrentSection] = useState(props.currentSection);

  const pausePeriod: ISelectItem[] = [
    { id: 1, label: '1 month' },
    { id: 2, label: '2 months' },
    { id: 3, label: '3 months' },
  ]

  const [selectedMothes, setSelectedMothes] = useState<ISelectItem>(pausePeriod[0]);

  const [currentFlow, setCurrentFlow] = useState<AccountClosePageSection>();

  const nonRenewingPlans: string[] = [
    "cb-pro-desktop-sep20",
    "cb-desktop-team-jan20",
  ];

  const planLoading = () =>
    closeCBAccountLoading ||
    pauseCBAccountLoading ||
    resumeCBAccountLoading ||
    removePauseCBAccountLoading ||
    removeCloseCBAccountLoading;

  const handlePauseMothesChange = (data: any) => {
    setSelectedMothes(data);
  };

  const isProMonthly = () => {
    const planIdParts = (user?.organization?.subscription?.planId || "").split(
      "-"
    );
    return (
      !!planIdParts.find((p: string) => p === "pro") &&
      !!planIdParts.find((p: string) => p === "monthly")
    );
  };
  const isAnnualOfferPlan = () => {
    const planIdParts = (user?.organization?.subscription?.planId || "").split(
      "-"
    );
    return (
      !!planIdParts.find((p: string) => p === "pro") &&
      !!planIdParts.find((p: string) => p === "offer") &&
      (!!planIdParts.find((p: string) => p === "m11") ||
        !!planIdParts.find((p: string) => p === "m10"))
    );
  };

  const isPlanPersonalMonthlyUsers = () =>
    isProMonthly() || isAnnualOfferPlan();

  const handlePlanPauseClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.pause.click");
    } else {
      track("left.account.close.warning.scheduledPause.click");
    }
    pauseCBAccount(selectedMothes.id);
  };

  const handlePersonalMonthlyOfferClick = () => {
    track("left.account.manageAccount.delete.claimOffer.click", {
      value1Type: "buttonName",
      value1: `extend for $${
        getPersonalMonthlyUserOffer(
          user?.organization?.subscription?.createdDate
        ).amount
      }`,
    });
    claimPersonalMonPlanOffer();
  };

  const handlePlanResumeClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "resume account",
        });
      } else {
        track("left.account.close.resume.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "resume account",
        });
      } else {
        track("left.account.close.warning.resume.click");
      }
    }
    resumeCBAccount();
  };

  const handlePlanRemoveCancelClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.delete.cancelReq.click", {
          value1: "cancel scheduled deletion",
        });
      } else {
        track("left.account.close.removeScheduledClose.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.delete.cancelReq.click", {
          value1: "cancel scheduled deletion",
        });
      } else {
        track("left.account.close.warning.removeScheduledClose.click");
      }
    }
    removeCloseCBAccount();
  };

  const handlePlanRemovePauseClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "cancel scheduled pause",
        });
      } else {
        track("left.account.close.removeScheduledPause.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "cancel scheduled pause",
        });
      } else {
        track("left.account.close.warning.removeScheduledPause.click");
      }
    }
    removePauseCBAccount();
  };

  const handleAddOffer = () => {
    if (currentSection === AccountClosePageSection.Main) {
      track("left.account.close.claimOffer.click");
    } else {
      track("left.account.close.warning.claimOffer.click");
    }
    addExtendSubOffer();
  };

  const [isPlanCancelLoading, setPlanCancelLoading] = useState(false);

  const cancelPlan = () => {
    const answers = {
      stepOneAnswer: cancelAnswers.questionOneMain,
      stepOneSubAnswer: cancelAnswers.questionOneSub,
      stepTwoAnswer: cancelAnswers.questionTwoMain,
      stepTwoSubAnswer: cancelAnswers.questionTwoSub,
    };
    if (user?.planType === Plan.Free || user?.planType === Plan.PublicTeam ) {
      track("left.account.close.close.click", {
        value1Type: "buttonName",
        value1: "noThanksCloseMyAccount",
      });
      closeUserAccount(answers);
    } else {
      track("left.account.close.dialog.close.click", {
        value1Type: "buttonName",
        value1: "cancel",
      });
      if( currentFlow === AccountClosePageSection.Cancel ) {
        closeCBAccount(answers);
      } else {
        deleteCBAccount(answers, 1);
      }
    }
  };

  const [cancelAnswers, setCancelAnswers] = useState({
    questionOneMain: "",
    questionOneSub: "",
    questionTwoMain: "",
    questionTwoSub: "",
  });

  const handleCancelStepOneClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.confirm.click", {
        value1: isAnnualOfferPlan() === true ? "with offer" : "without offer",
      });
    } else {
      track("left.account.close.warning.close.click", {
        value1Type: "buttonName",
        value1: "closeMyAccount",
      });
    }
    setCurrentSection(AccountClosePageSection.CancelStepOne);
  };

  const handleCancelStepTwoClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.q1.reason", {
        value1Type: "answer",
        value1: cancelAnswers.questionOneMain,
        value2Type: "reason",
        value2: cancelAnswers.questionOneSub,
      });
    } else {
      track("left.account.close.cancellation.q1.reason", {
        value1Type: "answer",
        value1: cancelAnswers.questionOneMain,
        value2Type: "reason",
        value2: cancelAnswers.questionOneSub,
      });
    }
    setCurrentSection(AccountClosePageSection.CancelStepTwo);
  };

  const handleFinalPlanCancelClick = () => {
    setPlanCancelLoading(true);
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.q2.altTool", {
        value1Type: "answer",
        value1: cancelAnswers.questionTwoMain,
        value2Type: "tool",
        value2: cancelAnswers.questionTwoSub,
      });
    } else {
      track("left.account.close.cancellation.q2.altTool", {
        value1Type: "answer",
        value1: cancelAnswers.questionTwoMain,
        value2Type: "tool",
        value2: cancelAnswers.questionTwoSub,
      });
    }
    cancelPlan();
  };

  const handleDoNotCancelClick = () => {
    setCancelAnswers({
      questionOneMain: "",
      questionOneSub: "",
      questionTwoMain: "",
      questionTwoSub: "",
    });
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.cancel.click", {
        value1Type: "location",
        value1:
          currentSection === AccountClosePageSection.CancelStepOne
            ? "q1"
            : currentSection === AccountClosePageSection.CancelStepTwo
            ? "q2"
            : "with-offer",
      });
    }
    setCurrentSection(AccountClosePageSection.Main);
  };

  // const handleDialogPlanCancelClick = () => {
  //   if (isPlanPersonalMonthlyUsers()) {
  //     track("left.account.manageAccount.delete.click", {
  //       value1: isAnnualOfferPlan() === true ? "with offer" : "without offer",
  //     });
  //   } else {
  //     track("left.account.close.navigate.warning.click", {
  //       value1Type: "buttonName",
  //       value1: "noThanksCloseMyAccount",
  //     });
  //   }
  //   setCurrentSection(AccountClosePageSection.CancelWarning);
  // };

  const showResumePlanButton = () => {
    return (
      user?.organization?.subscription?.paused ||
      user?.organization?.subscription?.pauseScheduled
    );
  };

  const getPersonalMonthlyUserOffer = (subStartedAt: string) => {
    const today = new Date();
    const days = differenceInDays(
      new Date(),
      new Date(parseInt(subStartedAt) * 1000)
    );
    const currency =
      user?.organization?.subscription?.preferredCurrency || "USD";
    if (today.getMonth() !== 1 && days < 31) {
      if (currency == "EUR") {
        return {
          amount: 27,
          extendMonths: 11,
        };
      } else if (currency == "AUD") {
        return {
          amount: 41,
          extendMonths: 11,
        };
      } else {
        return {
          amount: 32,
          extendMonths: 11,
        };
      }
      //if month is february and less than 30  with us.
    } else if (today.getMonth() === 1 && days < 30) {
      if (currency == "EUR") {
        return {
          amount: 27,
          extendMonths: 11,
        };
      } else if (currency == "AUD") {
        return {
          amount: 41,
          extendMonths: 11,
        };
      } else {
        return {
          amount: 32,
          extendMonths: 11,
        };
      }
    } else {
      if (currency == "EUR") {
        return {
          amount: 21,
          extendMonths: 10,
        };
      } else if (currency == "AUD") {
        return {
          amount: 32,
          extendMonths: 10,
        };
      } else {
        return {
          amount: 25,
          extendMonths: 10,
        };
      }
    }
  };

  const handleCancelSubOneClick = () => {
    setCurrentFlow(AccountClosePageSection.Cancel);
    setCurrentSection(AccountClosePageSection.CancelOrPause);
  };

  const handleCancelSubTwoClick = () => {
    setCurrentSection(AccountClosePageSection.Cancel);
  };

  const mainCloseAccountButton = (
    <div className={`${defaultClass}__main-description-buttons`}>
      <Permission permission={PermissionType.CanApplyOffers}>
        {!!!showResumePlanButton() &&
          !!!user?.organization?.subscription?.extendSubOfferExpired &&
          !!!user?.organization?.subscription?.cancelScheduled && (
           <Button
              intent={Intent.PRIMARY}
              loading={addExtendSubOfferLoading}
              onClick={handleAddOffer}
            >
              Claim Offer 🎉
            </Button>
          )}
      </Permission>
      <Permission permission={PermissionType.CanCancelPlan}>
        {user?.organization?.subscription?.paused && (
          <Button intent={Intent.PRIMARY} onClick={handlePlanResumeClick}>
            {t("pages.close.resumeMyAccount")}
          </Button>
        )}

        {user?.organization?.subscription?.pauseScheduled && (
          <Button intent={Intent.PRIMARY} onClick={handlePlanRemovePauseClick}>
            {t("pages.close.dontPauseMyAccount")}
          </Button>
        )}
      </Permission>
    </div>
  );


  const cancelResumeAccountButton = (
    <div className={`${defaultClass}__main-description-buttons`}>
      <Permission permission={PermissionType.CanCloseAccount}>
        <Button className="bp3-button bp3-intent-secondary" onClick={handleCancelStepOneClick}>
          {t("pages.close.closeMyAccount")}
        </Button>
      </Permission>
      <Permission permission={PermissionType.CanCancelPlan}>
        {user?.organization?.subscription?.pauseScheduled && (
          <Button intent={Intent.PRIMARY} onClick={handlePlanRemovePauseClick}>
            {t("pages.close.dontPauseMyAccount")}
          </Button>
        )}
        {user?.organization?.subscription?.paused && (
          <Button intent={Intent.PRIMARY} onClick={handlePlanResumeClick}>
            {t("pages.close.resumeMyAccount")}
          </Button>
        )}
      </Permission>
    </div>
  );

  const mainHeaderSection = () => (
    <>
    {/* banner section start */}
      <PageSection>
        <div>
          <div>
            {planLoading() && LoadingIndicator}
            {user?.organization?.subscription?.paymentFailedCount === 0 && user?.organization?.subscription?.paused && (
              <PageSection>
                <Callout intent={Intent.WARNING}>
                  {t("pages.close.yourAccountPaused")}
                  <Permission permission={PermissionType.CanCancelPlan}>
                    <a
                      onClick={handlePlanResumeClick}
                      className={`${defaultClass}__warning-card-btn`}
                    >
                      {t("pages.close.resumeMyAccount")}
                    </a>
                  </Permission>
                </Callout>
              </PageSection>
            )}
            {user?.organization?.subscription?.pauseScheduled && (
              <PageSection>
                <Callout intent={Intent.WARNING}>
                  {t("pages.billing.pauseScheduled")}&#32;
                  {t("pages.billing.pauseAtEndOfBillingPeriod", {
                    date: format(
                      new Date(
                        parseInt(user.organization.subscription.pauseDate) *
                          1000
                      ),
                      "PPP"
                    ),
                  })}
                  <Permission permission={PermissionType.CanCancelPlan}>
                    <a
                      onClick={handlePlanRemovePauseClick}
                      className={`${defaultClass}__warning-card-btn`}
                    >
                      {t("pages.close.dontPauseMyAccount")}
                    </a>
                  </Permission>
                </Callout>
              </PageSection>
            )}
            {user?.organization?.subscription?.cancelScheduled &&
             !user?.organization?.subscription?.deleteScheduled &&
              !nonRenewingPlans.includes(
                user?.organization?.subscription?.planId
              ) && (
                <PageSection>
                  <Callout intent={Intent.DANGER}>
                    {t("pages.billing.cancelScheduled")}&#32;
                    {t("pages.billing.cancelAtEndOfBillingPeriod", {
                      date: format(
                        new Date(
                          parseInt(user.organization.subscription.cancelDate) *
                            1000
                        ),
                        "PPP"
                      ),
                    })}
                    <Permission permission={PermissionType.CanCancelPlan}>
                      <a
                        onClick={handlePlanRemoveCancelClick}
                        className={`${defaultClass}__danger-card-btn`}
                      >
                        {t("pages.close.dontCancelMyAccount")}
                      </a>
                    </Permission>
                  </Callout>
                </PageSection>
              )}
              {user?.organization?.subscription?.deleteScheduled && (
                <PageSection>
                  <Callout intent={Intent.DANGER}>
                    {t("pages.billing.deleteScheduled")}&#32;
                    {t("pages.billing.deleteAtEndOfBillingPeriod", {
                      date: format(
                        new Date(
                          parseInt(user.organization.subscription.cancelDate) *
                            1000
                        ),
                        "PPP"
                      ),
                    })}
                    <Permission permission={PermissionType.CanCancelPlan}>
                      <a
                        onClick={handlePlanRemoveCancelClick}
                        className={`${defaultClass}__danger-card-btn`}
                      >
                        {t("pages.close.dontDeleteMyAccount")}
                      </a>
                    </Permission>
                  </Callout>
                </PageSection>
              )}
          </div>
          {user?.organization?.subscription?.cancelScheduled && (
            <div className="mt-2"></div>
          )}
        </div>
      </PageSection>
    {/* banner section end */}
    {/* main 3 icons section start */}
      <PageSection>
        <Row>
          <Col xs={12} md={4}>
            <Card>
              <div className={`${defaultClass}__border`}>
                <div className={`${defaultClass}__icon-tile`}>
                  <div className="card-icon">
                    <img
                          src="resources/images/close-account/close-account-plan-tile-team-old.svg"
                          width="100"
                    />
                  </div>
                  <label>{t("pages.close.yourPlan")}</label>
                  <br></br>
                  <span>
                    {user?.planType === Plan.Free && "Free Plan"}
                    {user?.planType !== Plan.Free && user?.planName}
                  </span>
                  <br></br>
                  <label>{t("pages.close.yourPlanHas")}<b>{t("pages.close.Unlimited")}</b>{t("pages.close.shapeLibraries")}</label>
                </div>
              </div>
            </Card>
          </Col>

          <Col xs={12} md={4}>
            <Card>
              <div className={`${defaultClass}__border`}>
                <div className={`${defaultClass}__icon-tile`}>
                  <div className="card-icon">
                    <img
                      src="resources/images/close-account/close-account-doc-count-tile-old.svg"
                      width="68"
                    />
                  </div>
                  <label>{t("pages.close.users")}</label>
                  <br></br>
                  <span>
                    {user?.organization?.size == -1 
                    ? `${user?.organization?.usedAccount|| 0}` 
                    : `${user?.organization?.size - user?.organization?.remainingSeats || 0}
                      /${user?.organization?.size || 0}`} {t("pages.close.users")}
                  </span>
                  <br></br>
                  <label>{t("pages.close.yourPlanHasUpTo")}<b>{user?.organization?.size == -1 ? `unlimited` : `${user?.organization?.size}`}</b>{t("pages.close.users")}</label>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <div className={`${defaultClass}__border`}>
                <div className={`${defaultClass}__icon-tile`}>
                  <div className="card-icon">
                    <img
                      src="resources/images/close-account/close-account-doc-count-tile-old.svg"
                      width="68"
                    />
                  </div>
                  <label>{t("pages.close.Workspaces")}</label>
                  <br></br>
                  <span>
                    {user?.organization?.id
                      ? user?.organization?.totalDocuments
                      : user?.totalDocuments} {t("pages.close.Workspaces")}
                  </span>
                  <br></br>
                  <label>{t("pages.close.yourPlanHas")} <b>{t("pages.close.Unlimited")}</b> {t("pages.close.Workspaces")}</label>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </PageSection>
    {/* main 3 icons section end */}
    </>
  );

  const upgradeYourPlanSection = (showHeader: boolean = true) => (
    <>
      <PageSection>
        <Card>
          {showHeader && (<h1 className={`${defaultClass}__section-title`} >{t("pages.close.upgradeYourPlan")}</h1>)}
          <div className={`${defaultClass}__border`}>
            <Row>
              <Col sm={12} md={12}>
                <div
                  className={`${defaultClass}__special-offer-banner ${defaultClass}__special-offer`}
                >
                  <h2 className="offer-title">{t("pages.close.exploreCreately")}</h2>
                  <p className="p-text">
                    {t("pages.close.mainDescriptionOne")} <br />
                    {t("pages.close.mainDescriptionTwo")} <br />
                    {t("pages.close.mainDescriptionFour")} <br />
                    {t("pages.close.mainDescriptionFive")}
                  </p>
                  {!user?.organization?.subscription.cancelled && mainCloseAccountButton}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </PageSection>
    </>
  )

  const pauseSubscriptionSection = (
    <>
      <PageSection>
        <Card>
          <div className={`${defaultClass}__pause-box`}>
            <label className={`${defaultClass}__pause-box-main-lable`} >{t("pages.close.pauseDescription1")}</label>
            <div className={`${defaultClass}__pause-box-main`}>
              <div>{IconPause}</div>
              <div className={`${defaultClass}__pause-box-text-area`}>
                <div className={`${defaultClass}__pause-box-main-lable`}>{t("pages.close.pauseDescription2")}</div>
                <div className={`${defaultClass}__pause-box-body-text`} >
                    <li>{t("pages.close.pauseDescription3")}</li>
                    <li>{t("pages.close.pauseDescription4")}</li>
                    <li>{t("pages.close.pauseDescription5")}</li>
                </div>
                <a className={`${defaultClass}__pause-box-read-more`}>{t("pages.close.pauseDescription6")}</a>
                <div className={`${defaultClass}__pause-box-select`}>
                  <div className={`${defaultClass}__pause-box-select-input`}>
                    <span className={`${defaultClass}__pause-box-select-input-text`}>{t("pages.close.pauseDescription7")}</span>
                    <div className={`${defaultClass}__pause-box-select-input-input`}>
                      <SelectInput
                        items={pausePeriod}
                        onChange={handlePauseMothesChange}
                        value={selectedMothes}
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      intent={Intent.PRIMARY}
                      onClick={handlePlanPauseClick}
                    >
                      {t("pages.close.pauseSubscription")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </PageSection>
    </>
  )

  const offerClaimedSection = !!user?.organization?.subscription
  ?.extendSubOfferStart ? (
  <>
    {mainHeaderSection()}
    <PageSection>
      <Card>
        <Row>
          <Col sm={12} md={12}>
            {/*Special offer claimed and offer started*/}
            <div
              className={`${defaultClass}__special-offer-banner ${defaultClass}__special-offer-started`}
            >
              <div>
                {" "}
                <img
                  src="resources/images/close-account/close-account-special-offer-icon.svg"
                  width="90"
                />{" "}
              </div>
              <h2>{t("pages.close.enjoyTheOffer")}</h2>
              <p>{t("pages.close.offerDescription01")}</p>
              <p className={`${defaultClass}__red-text`}>
                {user?.organization?.subscription?.currentTermEndDate &&
                  formatDistanceToNowStrict(
                    new Date(
                      parseInt(
                        user?.organization?.subscription?.currentTermEndDate
                      ) * 1000
                    )
                  )}{" "}
                <span className={`${defaultClass}__small-text`}>
                  (until{" "}
                  {user?.organization?.subscription?.currentTermEndDate &&
                    format(
                      new Date(
                        parseInt(
                          user?.organization?.subscription?.currentTermEndDate
                        ) * 1000
                      ),
                      "PPP"
                    )}
                  ).
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
    </PageSection>
  </>
) : (
  <>
    {mainHeaderSection()}
    <PageSection>
      <Card>
        <Row>
          <Col sm={12} md={12}>
            {/*Special offer claimed and waiting for start*/}
            <div
              className={`${defaultClass}__special-offer-banner ${defaultClass}__special-offer-pending`}
            >
              <div>
                {" "}
                <img
                  src="resources/images/close-account/close-account-special-offer-icon.svg"
                  width="90"
                />{" "}
              </div>
              <h2>{t("pages.close.enjoyTheOffer")}</h2>
              <p>{t("pages.close.offerDescription02")}</p>
              <p className={`${defaultClass}__red-text`}>
                {user?.organization?.subscription?.previousTermEndDate &&
                  format(
                    new Date(
                      parseInt(
                        user?.organization?.subscription?.previousTermEndDate
                      ) * 1000
                    ),
                    "PPP"
                  )}
              </p>
              <p>{t("pages.close.offerDescription03")}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </PageSection>
  </>
);
 

  // main section
  const mainManageAccountSection = (
    <>
      {user?.organization?.subscription.extendSubOfferClaim &&
        !user?.organization?.subscription.extendSubOfferExpired &&
        offerClaimedSection}
      {(!user?.organization?.subscription.extendSubOfferClaim ||
        (user?.organization?.subscription.extendSubOfferClaim &&
          user?.organization?.subscription.extendSubOfferExpired)) && (
        <>
          {mainHeaderSection()}
          {(!user?.organization?.subscription?.cancelScheduled &&
            (!user?.organization?.subscription?.paused &&
            !user?.organization?.subscription?.pauseScheduled)) && upgradeYourPlanSection()}
          {!user?.organization?.subscription?.cancelScheduled && ( 
            <PageSection>
              <div className={`${defaultClass}__cancel-subscription`}>
                <a onClick={handleCancelSubOneClick} >{t("pages.close.cancelSubscription")}</a>
              </div>
            </PageSection>
          )}
        </>
      )}
    </>
  );

  // cancel or pause 
  const cancelOrPauseSection = (
    <>
      {pauseSubscriptionSection}
      {upgradeYourPlanSection(false)}
      <PageSection>
        <div className={`${defaultClass}__cancel-subscription`}>
          <a onClick={handleCancelSubTwoClick} >{t("pages.close.cancelSubscription")}</a>
        </div>
      </PageSection>
    </>
  );

  // cancel
  const cancelSubscriptionSection = (
    <>
     <div className={`${defaultClass}__cancel-main`}>
      <label className={`${defaultClass}__cancel-main-header`}>{t("pages.close.cancelDescription1")}</label>
      <div className={`${defaultClass}__cancel-main-tearm`}>
        <div className={`${defaultClass}__cancel-main-tearm-header`}>{IconInfo} Important</div>
        <div className={`${defaultClass}__cancel-main-tearm-text`}>
          <span>{t("pages.close.cancelDescription2")}</span>
          <div>
            <li>{t("pages.close.cancelDescription3")}</li>
            <li>{t("pages.close.cancelDescription4")}</li>
            <li>{t("pages.close.cancelDescription5")}</li>
            <li>{t("pages.close.cancelDescription6")}</li>
            <li>{t("pages.close.cancelDescription7")}</li>
            <li>{t("pages.close.cancelDescription8")}</li>
          </div>
        </div>
      </div>
      <a className={`${defaultClass}__cancel-main-more-info`}>{t("pages.close.cancelDescription9")}</a>
      <div className={`${defaultClass}__cancel-main-check`}>
        <input
            type="checkbox"
            // checked={false}
            onChange={() => {}}
          />
        <label className={`${defaultClass}__cancel-main-check-text`}>
        {t("pages.close.cancelDescription10")}
        </label>
      </div>
      <div className={`${defaultClass}__cancel-main-buttons`}>
        <div>
          <Button
            intent={Intent.DANGER}
            onClick={handleCancelStepOneClick}
          >
            {t("pages.close.cancelSubscription")}
          </Button>
        </div>
        <div>
          <Button
            intent={Intent.NONE}
            onClick={() => { setCurrentSection(AccountClosePageSection.Main) }}
          >
            {t("pages.close.cancel")}
          </Button>
        </div>
      </div>
     </div>
    </>
  );

  const deleteHeaderSection = () => (
    <>
      <label className={`${defaultClass}__delete-header`}>{t("pages.close.deleteHeader")}</label>
      <div className={`${defaultClass}__delete-header-main`}>
        <div className={`${defaultClass}__delete-header-cards`}>
          <div className={`${defaultClass}__delete-header-card`}>
              <div className={`${defaultClass}__delete-header-card-icon`}>
                {IconTeam}
              </div>
              <div className={`${defaultClass}__delete-header-card-text`}>
                <div className={`${defaultClass}__delete-header-card-text-main`}>
                  <div className={`${defaultClass}__delete-header-card-text-main-xx`}>
                   {user?.planName}
                  </div>
                  <div className={`${defaultClass}__delete-header-card-text-main-x`}>
                    {user?.organization?.size == -1 
                    ? `${user?.organization?.usedAccount|| 0}` 
                    : `${user?.organization?.size - user?.organization?.remainingSeats || 0}`} {t("pages.close.usersWillBeLost")}
                  </div>
                </div>
                <div className={`${defaultClass}__delete-header-card-text-sub`}>
                {t("pages.close.yourPlanHasUnlimitedFoldersAndShapeLibraries")}
                </div>
              </div>
          </div>
          <div className={`${defaultClass}__delete-header-card`}>
              <div className={`${defaultClass}__delete-header-card-icon`}>
                {IconWorkspaces}
              </div>
              <div className={`${defaultClass}__delete-header-card-text`}>
                <div className={`${defaultClass}__delete-header-card-text-main`}>
                  <div className={`${defaultClass}__delete-header-card-text-main-xx`}>
                   {user?.organization?.id
                    ? user?.organization?.totalDocuments
                    : user?.totalDocuments}+ {t("pages.close.Workspaces")}
                  </div>
                  <div className={`${defaultClass}__delete-header-card-text-main-x`}>
                  {t("pages.close.willNoLongerBeAccessible")}
                  </div>
                </div>
                <div className={`${defaultClass}__delete-header-card-text-sub`}>
                {t("pages.close.yourPlanHasUnlimitedWorkspaces")}  
                </div>
              </div>
          </div>
        </div>
        <div className={`${defaultClass}__delete-header-points`}>
          <li>{t("pages.close.deleteDescription1")} </li>
          <li>{t("pages.close.all")} <b>{t("pages.close.documents")}</b>  {t("pages.close.createdInThisPlan")} <b>{t("pages.close.willBeDeleted")}</b> {t("pages.close.fromOurCreatelyServers")} </li>
          <li><b>{t("pages.close.deletedDocumentsCannotBeRecovered")}</b></li>
        </div>
      </div>
    </>
  );

  // delete account section 
  const DeleteAccountSection = (
    <>
      {user?.organization?.subscription.extendSubOfferClaim &&
        !user?.organization?.subscription.extendSubOfferExpired &&
        offerClaimedSection}
      {(!user?.organization?.subscription.extendSubOfferClaim ||
        (user?.organization?.subscription.extendSubOfferClaim &&
          user?.organization?.subscription.extendSubOfferExpired)) && (
        <>
        <div className={`${defaultClass}__delete-main`}>
          {deleteHeaderSection()}
          {upgradeYourPlanSection(false)}
          <div className={`${defaultClass}__delete-main-button-area`}>
            <Button
              intent={Intent.NONE}
              onClick={handleCancelStepOneClick}
            >
              {t("pages.close.deleteMyAccountAnyway")}
            </Button>
            <label className={`${defaultClass}__delete-main-footer-text`}>
              {t("pages.close.deleteDescription2")}
            </label>
          </div>
        </div>
        </>
      )}
    </>
  );

  
  useEffect(() => {
    if (!!closePageMainSection) {
      setCurrentSection(AccountClosePageSection.Main);
      reset();
    }
  }, [closePageMainSection]);

  const executeScrollTop = () => {
    if (refContainer != null && refContainer.current != null) {
      (refContainer.current as any).scrollIntoView(true);
    }
  };

  const getCurrentSection = () => {
    executeScrollTop();
    switch (currentSection) {
      case AccountClosePageSection.Main:
        return mainManageAccountSection;
      case AccountClosePageSection.CancelOrPause:
          return cancelOrPauseSection;
      case AccountClosePageSection.Cancel:
        return cancelSubscriptionSection;
      case AccountClosePageSection.OfferExpired:
        return mainManageAccountSection;
      case AccountClosePageSection.CancelStepOne:
        return cancelStepOneSection;
      case AccountClosePageSection.CancelStepTwo:
        return cancelStepTwoSection;
      case AccountClosePageSection.CancelWarning:
        return accountCancelWarningSections;
      case AccountClosePageSection.Delete:
          return DeleteAccountSection;
      default:
        return mainManageAccountSection;
    }
  };

  const cancelStepOneSection = (
    <div className={`${defaultClass}__q-border`}>
      <h3>Step 1 of 2</h3>
      <PageSection>
        <p>{t("pages.billing.cancelSectionTwo.question")}</p>
        <RadioGroup
          onChange={(event) =>
            setCancelAnswers({
              ...cancelAnswers,
              questionOneMain: event?.currentTarget.value,
            })
          }
          selectedValue={cancelAnswers.questionOneMain}
        >
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerOne")}
            value="Only wanted to see what Creately does"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerTwo")}
            value="We want to consolidate multiple accounts"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerThree")}
            value="The price is too expensive"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerFour")}
            value="I did not get sufficient value from Creately"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerFive")}
            value="Creately lacks the features and functionality I need"
            className={Classes.CARD}
          >
            <div className="bp3-radio-description">
              <TextArea
                rows={4}
                fill={true}
                placeholder={t(
                  "pages.billing.cancelSectionTwo.answerFiveDetailLabel"
                )}
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionOneSub: event?.currentTarget.value,
                  })
                }
              />
            </div>
          </Radio>
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerSix")}
            value="Other"
            className={Classes.CARD}
          >
            <div className="bp3-radio-description">
              <TextArea
                rows={4}
                fill={true}
                placeholder={t(
                  "pages.billing.cancelSectionTwo.answerSixDetailLabel"
                )}
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionOneSub: event?.currentTarget.value,
                  })
                }
              />
            </div>
          </Radio>
        </RadioGroup>
      </PageSection>

      <PageSection>
        <div className="text-center">
        { currentFlow === AccountClosePageSection.Cancel && 
          <a href="#" onClick={handleDoNotCancelClick}>
            {t("pages.billing.doNotCancel")}
          </a>
        }
        { currentFlow !== AccountClosePageSection.Cancel && 
            <a href="#" onClick={handleDoNotCancelClick}>   
            {t("pages.billing.doNotDelete")}
          </a>
        }
          <Button
            disabled={
              (!!!cancelAnswers.questionOneMain.length &&
                cancelAnswers.questionOneMain !== "Other") ||
              (cancelAnswers.questionOneMain === "Other" &&
                !!!cancelAnswers.questionOneSub.trim().length)
            }
            className="ml-3"
            intent={Intent.PRIMARY}
            onClick={handleCancelStepTwoClick}
          >
            {t("next")}
          </Button>
        </div>
      </PageSection>
    </div>
  );

  const cancelStepTwoSection = (
    <div className={`${defaultClass}__q-border`}>
      <h3>Step 2 of 2</h3>
      <PageSection>
        <p>{t("pages.billing.cancelSectionThree.question")}</p>
        <RadioGroup
          onChange={(event) => {
            if (event?.currentTarget.value == "no") {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoMain: event?.currentTarget.value,
                questionTwoSub: "",
              });
            } else {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoSub: "",
                questionTwoMain: event?.currentTarget.value,
              });
            }
          }}
          selectedValue={cancelAnswers.questionTwoMain}
        >
          <Radio label={t("yes")} value="yes" className={Classes.CARD}>
            <div className="bp3-radio-description">
              <RadioGroup
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionTwoSub: event?.currentTarget.value,
                  })
                }
                selectedValue={cancelAnswers.questionTwoSub}
              >
                <Radio
                  label={t("pages.billing.cancelSectionThree.lucidChart")}
                  value="lucidChart"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.drawIo")}
                  value="drawIo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.miro")}
                  value="miro"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.googleDraw")}
                  value="google draw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.mural")}
                  value="mural"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.msVisio")}
                  value="ms visio"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.smartDraw")}
                  value="smartDraw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.cacoo")}
                  value="cacoo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.other")}
                  value="other"
                />
              </RadioGroup>
            </div>
          </Radio>
          <Radio label={t("no")} value="no" className={Classes.CARD} />
          <Radio
            label={t("pages.billing.cancelSectionThree.maybe")}
            value="maybe"
            className={Classes.CARD}
          >
            <div className="bp3-radio-description">
              <RadioGroup
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionTwoSub: event?.currentTarget.value,
                  })
                }
                selectedValue={cancelAnswers.questionTwoSub}
              >
                <Radio
                  label={t("pages.billing.cancelSectionThree.lucidChart")}
                  value="lucidChart"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.drawIo")}
                  value="drawIo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.miro")}
                  value="miro"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.googleDraw")}
                  value="google draw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.mural")}
                  value="mural"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.msVisio")}
                  value="ms visio"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.smartDraw")}
                  value="smartDraw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.cacoo")}
                  value="cacoo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.other")}
                  value="other"
                />
              </RadioGroup>
            </div>
          </Radio>
        </RadioGroup>
      </PageSection>
      <PageSection>
        <div className="text-center">
        { currentFlow === AccountClosePageSection.Cancel &&
        <>
          <a href="#" onClick={handleDoNotCancelClick}>
            {t("pages.billing.doNotCancel")}
          </a>
          <Button
            disabled={
              !(
                (cancelAnswers.questionTwoSub.length > 0 &&
                  cancelAnswers.questionTwoMain !== "no" &&
                  cancelAnswers.questionTwoSub.length > 0) ||
                cancelAnswers.questionTwoMain === "no"
              )
            }
            className="ml-3"
            intent={Intent.DANGER}
            loading={isPlanCancelLoading || planLoading()}
            onClick={handleFinalPlanCancelClick}
          >
            {t("pages.billing.cancelPlan")}
          </Button>
          </>
        }
        { currentFlow !== AccountClosePageSection.Cancel && 
        <>
            <a href="#" onClick={handleDoNotCancelClick}>   
            {t("pages.billing.doNotDelete")}
          </a>
          <Button
            disabled={
              !(
                (cancelAnswers.questionTwoSub.length > 0 &&
                  cancelAnswers.questionTwoMain !== "no" &&
                  cancelAnswers.questionTwoSub.length > 0) ||
                cancelAnswers.questionTwoMain === "no"
              )
            }
            className="ml-3"
            intent={Intent.DANGER}
            loading={isPlanCancelLoading || planLoading()}
            onClick={handleFinalPlanCancelClick}
          >
            {t("pages.billing.deletePlan")}
          </Button>
          </>
        }
        </div>
      </PageSection>
    </div>
  );

  const cancelWarningSection = (
    <PageSection>
      <Row>
        <Col xs={12} md={12}>
          <Card className="close-account-container">
            <div className={`${defaultClass}__warning-screen`}>
              <div
                className={`${defaultClass}__warning-section ${defaultClass}__cancellation-warning`}
              >
                <h3>{t("pages.close.closeAccountWarning.warningTitle")}</h3>
                <Row>
                  <Col xs={12} md={2}>
                    <div className="card-icon close-account-icon">
                      <img
                        src="resources/images/close-account/close-account-close-icon.svg"
                        width="85"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={10}>
                    <ul className={`${defaultClass}__warning-screen__bullets col-xs-12 col-md-10`}>
                      <li>
                        {t("pages.close.closeAccountWarning.warningDescription01")}
                      </li>
                      <li>
                        {t("pages.close.closeAccountWarning.warningDescription02")}
                      </li>
                      <li>
                        {t("pages.close.closeAccountWarning.warningDescription03")}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              {!!planLoading() || !!addExtendSubOfferLoading ? (
                <div
                  className={`${defaultClass}__warning-section ${defaultClass}__pause-description`}
                >
                  <Spinner
                    className={`loading-indicator__spinner`}
                    intent={Intent.PRIMARY}
                  />
                </div>
              ) : (
                <>
                  {user?.organization?.subscription?.paused && (
                    <div
                      className={`${defaultClass}__warning-section ${defaultClass}__pause-description`}
                    >
                      <div className="card-icon">
                        <img
                          src="resources/images/close-account/close-account-close-icon.svg"
                          width="85"
                        />
                      </div>
                      <h3>{t("pages.close.yourAccountPaused")}</h3>
                      <p>{t("pages.close.yourAccountPausedDescription")}</p>
                      {cancelResumeAccountButton}
                    </div>
                  )}

                  {user?.organization?.subscription?.pauseScheduled && (
                    <div
                      className={`${defaultClass}__warning-section ${defaultClass}__pause-description`}
                    >
                      <div className="card-icon">
                        <img
                          src="resources/images/close-account/close-account-pause-icon.svg"
                          width="85"
                        />
                      </div>
                      <h3>{t("pages.close.yourAccountScheduledPause")}</h3>
                      <p>
                        {t("pages.close.yourAccountScheduledPauseDescription1")}{" "}
                        <span className={`${defaultClass}__red-text`}>
                          {format(
                            new Date(
                              parseInt(
                                user?.organization?.subscription
                                  ?.currentTermEndDate
                              ) * 1000
                            ),
                            "PPP"
                          )}{" "}
                        </span>
                        {t("pages.close.yourAccountScheduledPauseDescription2")}
                      </p>
                      {cancelResumeAccountButton}
                    </div>
                  )}
                  {!!!user?.organization?.subscription?.pauseScheduled &&
                    !!!user?.organization?.subscription?.paused &&
                    user?.planType !== Plan.Free && (
                      <div
                        className={`${defaultClass}__warning-section ${defaultClass}__pause-description`}
                      > 
                        <h3>
                        {t(
                          "pages.close.closeAccountWarning.warningPauseTitle"
                        )}
                        </h3>
                        <Row>
                        <Col xs={12} md={2} className="card-icon">
                          <img
                            src="resources/images/close-account/close-account-pause-icon.svg"
                            width="85"
                          />
                        </Col>
                        <Col xs={12} md={10}>
                        <ul
                          className={`${defaultClass}__warning-screen__bullets pause-text`}
                        >
                          <li>
                            {t(
                              "pages.close.closeAccountWarning.warningPauseDescription01"
                            )}
                          </li>
                          <li>
                            {t(
                              "pages.close.closeAccountWarning.warningPauseDescription02"
                            )}
                          </li>
                          <li>
                            {t(
                              "pages.close.closeAccountWarning.warningPauseDescription03"
                            )}
                          </li>
                          <li>
                            {t(
                              "pages.close.closeAccountWarning.warningPauseDescription04"
                            )}
                          </li>
                        </ul>
                        </Col>
                        </Row>
                        <p>
                          <a
                            href="https://support.creately.com/hc/en-us/articles/360002323515"
                            target="_blank"
                          >
                            {t(
                              "pages.close.closeAccountWarning.warningPauseReadMore"
                            )}
                          </a>
                        </p>
                        {mainCloseAccountButton}
                        <Permission permission={PermissionType.CanApplyOffers}>
                          {!!!user?.organization?.subscription
                            ?.extendSubOfferClaim && (
                            <a onClick={handleAddOffer}>
                              {t("pages.close.extendYourPlanCloselater")}
                            </a>
                          )}
                        </Permission>
                      </div>
                    )}
                  {!!!user?.organization?.subscription?.pauseScheduled &&
                    !!!user?.organization?.subscription?.paused &&
                    user?.planType === Plan.Free && (
                      <PageSection>
                        <div className="text-center">
                          <a href="#" onClick={handleDoNotCancelClick}>
                            {t("pages.billing.doNotCancel")}
                          </a>
                          <Button
                            className="ml-3"
                            intent={Intent.DANGER}
                            onClick={handleCancelStepOneClick}
                          >
                            {t("continue")}
                          </Button>
                        </div>
                      </PageSection>
                    )}
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </PageSection>
  );

  const cancelPersonalSection = (
    <>
      {planLoading() && LoadingIndicator}
      <PageSection>
        <Card className="pb-0">
          <Row>
            <Col sm={12} md={12}>
              <div className={`${defaultClass}__special-offer-banner`}>
                <h2 className={`${defaultClass}__red-text`}>
                  {t(
                    "pages.close.personalMonthlyUsers.deleteAccountWarningTitle"
                  )}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Card className="pb-0 pt-0">
                <div className={`${defaultClass}__personal-icon-tile`}>
                  <div className="card-icon">
                    {(user?.planType === Plan.Free ||
                      user?.planType === Plan.Personal ||
                      user?.planType === Plan.Pro ||
                      user?.planType === Plan.Lite) && (
                      <img src="resources/images/close-account/close-account-delete_warning-plan.svg" />
                    )}
                    {user?.planType === Plan.Team && (
                      <img src="resources/images/close-account/close-account-plan-tile-team.svg" />
                    )}
                  </div>
                  <div className="card-text">
                    <label>
                      {user?.planType === Plan.Free && "Free Plan"}
                      {user?.planType !== Plan.Free && user?.planName}
                      {user?.planType === Plan.Team &&
                        ` (${user?.organization?.size})`}
                    </label>
                    <br></br>
                    <span className="smaller-plan-text">
                      {t(
                        "pages.close.personalMonthlyUsers.deleteAccountWarningDescription01"
                      )}
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="pb-0 pt-0">
                <div className={`${defaultClass}__personal-icon-tile`}>
                  <div className="card-icon">
                    <img src="resources/images/close-account/close-account-delete_warning-file.svg" />
                  </div>
                  <div className="card-text">
                    <label>
                      {user?.organization?.id
                        ? user?.organization?.totalDocuments
                        : user?.totalDocuments}{" "}
                      {t(
                        "pages.close.personalMonthlyUsers.deleteAccountWarningDescription02.1"
                      )}
                    </label>
                    <br></br>
                    <label className="small-card-text">
                      {t(
                        "pages.close.personalMonthlyUsers.deleteAccountWarningDescription02.2"
                      )}{" "}
                      <b>
                        {user?.organization?.id
                          ? user?.organization?.totalFolders
                          : user?.totalFolders}{" "}
                        {t(
                          "pages.close.personalMonthlyUsers.deleteAccountWarningDescription02.3"
                        )}
                      </b>
                    </label>
                    <br></br>
                    <span className="smaller-plan-text">
                      {t(
                        "pages.close.personalMonthlyUsers.deleteAccountWarningDescription02.4"
                      )}
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
        {isAnnualOfferPlan() && (
          <Card>
            <Row>
              <div
                className={`${defaultClass}__personal-delete-account-buttons`}
              >
                {isAnnualOfferPlan() && (
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={handleDoNotCancelClick}
                  >
                    {t("pages.close.personalMonthlyUsers.keepMyAccount")}
                  </Button>
                )}
                {isProMonthly() && (
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={handlePersonalMonthlyOfferClick}
                  >
                    {user?.organization?.subscription?.preferredCurrency ===
                      "USD" &&
                      t("pages.close.personalMonthlyUsers.extendForUSD")}
                    {user?.organization?.subscription?.preferredCurrency ===
                      "EUR" &&
                      t("pages.close.personalMonthlyUsers.extendForEUR")}
                    {user?.organization?.subscription?.preferredCurrency ===
                      "AUD" &&
                      t("pages.close.personalMonthlyUsers.extendForAUD")}
                    {
                      getPersonalMonthlyUserOffer(
                        user?.organization?.subscription?.createdDate
                      ).amount
                    }
                  </Button>
                )}
                <Permission permission={PermissionType.CanCloseAccount}>
                  <Button
                    onClick={handleCancelStepOneClick}
                    className={defaultClass + "__red-text-delete-btn"}
                  >
                    {t("pages.close.personalMonthlyUsers.deleteMyAccount")}
                  </Button>
                </Permission>
              </div>
            </Row>
          </Card>
        )}
        {isProMonthly() && (
          <Card>
            <div>
              <Col sm={12} md={12}>
                <div className={`${defaultClass}__offer-box`}>
                  <div
                    className={`${defaultClass}__special-offer-banner ${defaultClass}__special-offer`}
                  >
                    <h2>
                      <span>
                        {t("pages.close.personalMonthlyUsers.offerTitle1")}
                      </span>
                      {t("pages.close.personalMonthlyUsers.offerTitle2")}
                    </h2>
                  </div>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className={`${defaultClass}__offer-box-text`}>
                        <p>
                          {t(
                            "pages.close.personalMonthlyUsers.offerDescription1"
                          )}
                        </p>
                        <span>
                          {" "}
                          {format(
                            addMonths(
                              new Date(),
                              getPersonalMonthlyUserOffer(
                                user?.organization?.subscription?.createdDate
                              ).extendMonths
                            ),
                            "PPP"
                          )}
                        </span>
                        <span className={`${defaultClass}__offer-tooltip-icon`}>
                          <Tooltip
                            content={
                              <span>
                                {t(
                                  "pages.close.personalMonthlyUsers.toolTip.offerToolTip1"
                                )}
                                <br></br>
                                {t(
                                  "pages.close.personalMonthlyUsers.toolTip.offerToolTip2"
                                )}
                                <br></br>
                                {t(
                                  "pages.close.personalMonthlyUsers.toolTip.offerToolTip3"
                                )}
                                <b>
                                  {t(
                                    "pages.close.personalMonthlyUsers.toolTip.offerToolTip4"
                                  )}
                                </b>
                              </span>
                            }
                            position={Position.BOTTOM}
                          >
                            <Icon
                              icon="issue"
                              iconSize={Icon.SIZE_STANDARD}
                              className={defaultClass + "__icon"}
                            />
                          </Tooltip>
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className={`${defaultClass}__offer-box-text-box`}>
                        <p>
                          {t(
                            "pages.close.personalMonthlyUsers.offerDescription2"
                          )}
                        </p>
                        <span className="dollar-text">
                          {user?.organization?.subscription
                            ?.preferredCurrency === "USD" && "$"}
                          {user?.organization?.subscription
                            ?.preferredCurrency === "EUR" && "€"}
                          {user?.organization?.subscription
                            ?.preferredCurrency === "AUD" && "A$"}
                        </span>
                        <span className="amount-text">
                          {
                            getPersonalMonthlyUserOffer(
                              user?.organization?.subscription?.createdDate
                            ).amount
                          }
                        </span>
                        <span className="saving-text">
                          {t(
                            "pages.close.personalMonthlyUsers.offerDescription3"
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className={`${defaultClass}__special-offer-buttons`}>
                      {isAnnualOfferPlan() && (
                        <Button
                          intent={Intent.PRIMARY}
                          onClick={handleDoNotCancelClick}
                        >
                          {t("pages.close.personalMonthlyUsers.keepMyAccount")}
                        </Button>
                      )}
                      {isProMonthly() && (
                        <Button
                          intent={Intent.PRIMARY}
                          onClick={handlePersonalMonthlyOfferClick}
                        >
                          {user?.organization?.subscription
                            ?.preferredCurrency === "USD" &&
                            t("pages.close.personalMonthlyUsers.extendForUSD")}
                          {user?.organization?.subscription
                            ?.preferredCurrency === "EUR" &&
                            t("pages.close.personalMonthlyUsers.extendForEUR")}
                          {user?.organization?.subscription
                            ?.preferredCurrency === "AUD" &&
                            t("pages.close.personalMonthlyUsers.extendForAUD")}
                          {
                            getPersonalMonthlyUserOffer(
                              user?.organization?.subscription?.createdDate
                            ).amount
                          }
                        </Button>
                      )}
                      <Permission permission={PermissionType.CanCloseAccount}>
                        <Button
                          onClick={handleCancelStepOneClick}
                          className={defaultClass + "__red-text-delete-btn"}
                        >
                          {t(
                            "pages.close.personalMonthlyUsers.deleteMyAccount"
                          )}
                        </Button>
                      </Permission>
                    </div>
                  </Row>
                  <Row>
                    <div
                      className={`${defaultClass}__special-offer-benefits-banner`}
                    >
                      <p>
                        {t(
                          "pages.close.personalMonthlyUsers.offerBenefitTitle"
                        )}
                      </p>
                      <table className={`${defaultClass}__creately-benefits`}>
                        <tbody>
                          <tr>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-1.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t(
                                "pages.close.mainBenefits.planBusinessStrategy"
                              )}
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-2.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t("pages.close.mainBenefits.brainstormIdeas")}
                            </td>
                          </tr>

                          <tr>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-3.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t("pages.close.mainBenefits.createFlowcharts")}
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-4.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t(
                                "pages.close.mainBenefits.documentResearchVisually"
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-5.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t(
                                "pages.close.mainBenefits.runMeetingsorWorkshops"
                              )}
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-6.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t("pages.close.mainBenefits.useItAsAWhiteboard")}
                            </td>
                          </tr>

                          <tr>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-7.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t(
                                "pages.close.mainBenefits.planSoftwareProjects"
                              )}
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-icon`}
                            >
                              <img
                                src="resources/images/close-account/close-account-creately-benefits-8.svg"
                                width="25"
                              />
                            </td>
                            <td
                              className={`${defaultClass}__creately-benefits-text`}
                            >
                              {t("pages.close.mainBenefits.andManyMore")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Row>
                </div>
              </Col>
            </div>
          </Card>
        )}
      </PageSection>

      {!showResumePlanButton() && !user?.organization?.subscription?.cancelled && (
        <PageSection>
          <Card>
            <div
              className={`${defaultClass}__personal-pause-icon-tile ${defaultClass}__warning-pause-description`}
            >
              <div className="card-icon">
                <img
                  src="resources/images/close-account/close-account-pause-icon.svg"
                  width="80"
                />
              </div>
              <div className="card-pause-text">
                <label className="pause-text">
                  {t("pages.close.personalMonthlyUsers.offerPauseTitle")}
                </label>
                <br></br>
                <ul>
                  <li>
                    {t("pages.close.personalMonthlyUsers.pausedProList1")}
                  </li>
                  <li>
                    {t("pages.close.personalMonthlyUsers.pausedProList2")}
                  </li>
                  <li>
                    {" "}
                    {t("pages.close.personalMonthlyUsers.pausedProList3")}
                  </li>
                </ul>
                <Permission permission={PermissionType.CanCancelPlan}>
                  <Button intent={Intent.NONE} onClick={handlePlanPauseClick}>
                    {t("pages.close.pauseMyAccount")}
                  </Button>
                </Permission>
              </div>
            </div>
          </Card>
        </PageSection>
      )}

      {user?.organization?.subscription?.paused && (
        <PageSection>
          <div className={`${defaultClass}__paused-schedule-card`}>
            <Card>
              <div
                className={`${defaultClass}__personal-pause-icon-tile ${defaultClass}__warning-pause-description`}
              >
                <div className="card-icon">
                  <img
                    src="resources/images/close-account/close-account-pause-icon.svg"
                    width="80"
                  />
                </div>
                <div className="card-pause-text">
                  <label className="pause-text">
                    {t("pages.close.yourAccountPaused")}
                  </label>
                  <br></br>
                  <ul>
                    <li>
                      {t("pages.close.personalMonthlyUsers.pausedConList1")}
                    </li>
                    <li>
                      {t("pages.close.personalMonthlyUsers.pausedConList2")}
                    </li>
                  </ul>
                  <Permission permission={PermissionType.CanCancelPlan}>
                    <Button
                      intent={Intent.PRIMARY}
                      onClick={handlePlanResumeClick}
                    >
                      {t("pages.close.personalMonthlyUsers.reactivateAccount")}
                    </Button>
                  </Permission>
                </div>
              </div>
            </Card>
          </div>
        </PageSection>
      )}

      {user?.organization?.subscription?.pauseScheduled && (
        <PageSection>
          <div className={`${defaultClass}__paused-schedule-card`}>
            <Card>
              <div
                className={`${defaultClass}__personal-pause-icon-tile ${defaultClass}__warning-pause-description`}
              >
                <div className="card-icon">
                  <img
                    src="resources/images/close-account/close-account-pause-icon.svg"
                    width="80"
                  />
                </div>
                <div className="card-pause-text">
                  <label className="pause-text">
                    {t("pages.close.yourAccountScheduledPauseDescription1")}
                    <span className={`${defaultClass}__red-text-big`}>
                      {format(
                        new Date(
                          parseInt(user.organization.subscription.pauseDate) *
                            1000
                        ),
                        "PPP"
                      )}
                    </span>
                    <span className={`${defaultClass}__small-text`}>
                      {t("pages.close.yourAccountScheduledPauseDescription3")}
                    </span>
                  </label>
                  <br></br>
                  <ul>
                    <li>
                      {t("pages.close.personalMonthlyUsers.pausedConList1")}
                    </li>
                    <li>
                      {t("pages.close.personalMonthlyUsers.pausedConList2")}
                    </li>
                  </ul>
                  <Permission permission={PermissionType.CanCancelPlan}>
                    <Button
                      intent={Intent.PRIMARY}
                      onClick={handlePlanRemovePauseClick}
                    >
                      {t("pages.close.personalMonthlyUsers.reactivateAccount")}
                    </Button>
                  </Permission>
                </div>
              </div>
            </Card>
          </div>
        </PageSection>
      )}
    </>
  );

  const accountCancelWarningSections = (
    <>
      {(isProMonthly() || isAnnualOfferPlan()) && cancelPersonalSection}
      {!(isProMonthly() || isAnnualOfferPlan()) && cancelWarningSection}
    </>
  );

  return userLoading ? (
    LoadingIndicator
  ) : (
    <div className={defaultClass} ref={refContainer}>
      <Grid fluid>
        <PageSection>
        { currentSection === AccountClosePageSection.Main && (<PageTitle>{t("closeAccount")}</PageTitle>) } 
        { currentSection === AccountClosePageSection.CancelOrPause && (<PageTitle>Cancel Subscription</PageTitle>) }
        { currentSection === AccountClosePageSection.Delete && (<PageTitle>Delete Your Account</PageTitle>) }   
        </PageSection>
        <Card>
          <div className={`${defaultClass}__main-border`}>
            {getCurrentSection()}
          </div>
        </Card>
      </Grid>
    </div>
  );
};
