import {
  Button,
  Callout,
  Card,
  Classes,
  Icon,
  // Icon,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { getData, getName } from "country-list";
import { useActions, useValues } from "kea";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal/modal";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import {
  Permission,
  PermissionType,
  Plan,
} from "../../components/permissions/permissions";
import { RatingCard } from "../../components/rating-card/rating-card";
import {
  ISelectItem,
  SelectInput,
} from "../../components/select-input/select-input";
import { formatNextBillingDate } from "../../helpers/date.h";
import { appLogic } from "../../logics/app.logic";
import { billingLogic } from "../../logics/billing.logic";
import { organizationLogic } from "../../logics/organization.logic";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import "./billing-page.scss";
import { UserFilterInput } from "../../components/user-filter-input/user-filter-input";
import { BillingContactItem } from "./billing-contact-item/billing-contact-item";
import { OwnerDetailsPopup } from "./owner-details-popup/owner-details-popup";
// import { BillingInvoicesTable } from "../../components/billing-invoices-table/billing-invoices-table";
import { IComponentProps } from "../../component-props.i";
import { BillingInvoicesTable } from "./billing-invoices-table/billing-invoices-table";

// TODO: Show self with updated information after chargebee widget is closed
export enum BillingPageSection {
  Billing = "billing",
  CancelStepOne = "cancelStepOne",
  CancelStepTwo = "cancelStepTwo",
  CancelStepThree = "cancelStepThree",
  AddBillingContact = "addBillingContact",
  ChangeBillingAddress = "changeBillingAddress",
}
export interface IBillingContact {
  email: string;
  name: string;
  isYou: boolean;
  thumbnail?: string;
  role?: string;
}

/**
 * Chargebee subscription statuses.
 * https://apidocs.chargebee.com/docs/api/subscriptions
 */
export enum SubscriptionStatus {
  /**
   * The Subscription is scheduled to start in a future date.
   */
  Future = "future",

  /**
   * The subscription is in trial.
   */
  Trial = "in_trial",

  /**
   * The subscription is in active state and will be charged at start of each term based on the recurring items(plan & addons etc.,).
   */
  Active = "active",

  /**
   * The subscription will be cancelled at end of the current term.
   */
  NonRenewing = "non_renewing",

  /**
   * The subscription is paused. No new recurring actions will be allowed, but any pending payments will be collected.
   */
  Paused = "paused",

  /**
   * The subscription has been cancelled. No new recurring actions will take place, but any pending payments will be collected.
   */
  Cancelled = "cancelled,",
}

/**
 * Subscription status changes to be sent to API.
 */
export enum SubscriptionStatusChange {
  /**
   * Pause the plan.
   */
  Pause = "pause",

  /**
   * Cancel the plan.
   */
  Cancel = "cancel",

  /**
   * Resume a paused plan.
   */
  Resume = "resume",

  /**
   * Delete plan.
   */
  Delete = "delete",

  /**
   * Remove a scheduled plan pause.
   */
  RemoveScheduledPause = "removeScheduledPause",

  /**
   * Remove a scheduled plan cancellation.
   */
  RemoveScheduledCancellation = "removeScheduledCancellation",
}

export interface IBillingPageProps extends IComponentProps {
  openPaymentSources: () => void;
}

export const BillingPage: React.FC<IBillingPageProps> = (props) => {
  const defaultClass = "billing-page";
  const { t } = useTranslation();
  const { track } = useActions(appLogic);
  const { register, handleSubmit, setError, errors } = useForm();

  const { changePlanStatus } = useActions(billingLogic);

  const { user }: { user: IUser; userLoading: boolean } = useValues(userLogic);

  const handleInvoicesTabClick = () => {
    track("accountPortal.billing.invoices.click");
  };

  const countries: ISelectItem[] = getData().map((item) => ({
    id: item.code,
    label: item.name,
  }));

  // sort the countries list by label property
  countries.sort((a, b) => a.label.localeCompare(b.label));

  const [selectedCountry, setSelectedCountry] = useState<
    ISelectItem | undefined
  >(
    countries.find(
      (country) => country.id === user?.organization?.billingAddress?.country
    )
  );

  const showPausePlanButton = () => {
    return (
      !user?.organization?.subscription?.paused &&
      !user?.organization?.subscription?.pauseScheduled &&
      !user?.organization?.subscription?.cancelled &&
      !user?.organization?.subscription?.cancelScheduled
    );
  };

  const [isPlanCancelDialogOpen, setPlanCancelDialogOpen] = useState(false);

  const { updateOrganization } = useActions(organizationLogic);

  const handleUpdateBillingAddress = (billingAddress: any) => {
    track("accountPortal.billing.changeAddress.click", {
      value1Type: "button",
      value1: "button label - Save",
    });
    if (!!!selectedCountry) {
      setError("country", "required");
      return;
    }
    updateOrganization({
      id: user.organization.id,
      billingAddress: {
        line1: billingAddress.line1,
        city: billingAddress.city,
        zipCode: billingAddress.zipCode,
        country: selectedCountry?.id,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        phone: billingAddress.phone,
        line2: billingAddress.line2,
        company: billingAddress.company,
      },
    });
    setCurrentSection(BillingPageSection.Billing);
  };

  const handleUpdateAddressCancelButtonClick = () => {
    track("accountPortal.billing.changeAddress.click", {
      value1Type: "button",
      value1: "Cancel",
    });
    setCurrentSection(BillingPageSection.Billing);
  };

  const handleCancelStepTwoClick = () => {
    track("left.account.billing.cancellation.q1.rating", {
      value1Type: "rating",
      value1: cancelAnswers.questionOne,
    });
    setCurrentSection(BillingPageSection.CancelStepTwo);
  };

  const handleCancelStepThreeClick = () => {
    track("left.account.billing.cancellation.q2.reason", {
      value1Type: "answer",
      value1: cancelAnswers.questionTwoMain,
      value2Type: "description",
      value2: cancelAnswers.questionTwoSub,
    });
    setCurrentSection(BillingPageSection.CancelStepThree);
  };
  const [isPlanCancelLoading, setPlanCancelLoading] = useState(false);
  const handleFinalPlanCancelClick = () => {
    setPlanCancelLoading(true);
    track("left.account.billing.cancellation.q3.altTool", {
      value1Type: "answer",
      value1: cancelAnswers.questionThreeMain,
      value2Type: "tool",
      value2: cancelAnswers.questionThreeSub,
    });
    cancelPlan();
    setCurrentSection(BillingPageSection.Billing);
  };
  const cancelPlan = () => {
    changePlanStatus(SubscriptionStatusChange.Cancel);
  };

  const handleDoNotCancelClick = () => {
    setCancelAnswers({
      questionOne: "",
      questionTwoMain: "",
      questionTwoSub: "",
      questionThreeMain: "",
      questionThreeSub: "",
    });
    setCurrentSection(BillingPageSection.Billing);
  };

  const handleDialogPlanCancelClick = () => {
    track("left.account.billing.dialog.cancel.click", {
      value1Type: "buttonName",
      value1: "cancel",
    });
    setPlanCancelDialogOpen(false);
    setCurrentSection(BillingPageSection.CancelStepOne);
  };

  const handleDialogPlanPauseClick = () => {
    track("left.account.billing.dialog.cancel.click", {
      value1Type: "buttonName",
      value1: "pause",
    });
    setPlanCancelDialogOpen(false);
    pausePlan();
  };

  const pausePlan = () => {
    changePlanStatus(SubscriptionStatusChange.Pause);
  };

  const handleDialogCloseClick = () => {
    track("left.account.billing.dialog.cancel.click", {
      value1Type: "buttonName",
      value1: "doNothing",
    });
    setPlanCancelDialogOpen(false);
  };

  const [currentSection, setCurrentSection] = useState(
    BillingPageSection.Billing
  );

  const [cancelAnswers, setCancelAnswers] = useState({
    questionOne: "",
    questionTwoMain: "",
    questionTwoSub: "",
    questionThreeMain: "",
    questionThreeSub: "",
  });

  const geCurrentSection = () => {
    if (currentSection === BillingPageSection.CancelStepOne) {
      return cancelStepOneSection;
    } else if (currentSection === BillingPageSection.CancelStepTwo) {
      return cancelStepTwoSection;
    } else if (currentSection === BillingPageSection.AddBillingContact) {
      return AddBillingContacts;
    } else if (currentSection === BillingPageSection.CancelStepThree) {
      return cancelStepThreeSection;
    } else if (currentSection === BillingPageSection.ChangeBillingAddress) {
      return ChangeBillingAddress;
    } else {
      return billingInfoInvoices;
    }
  };

  const billingValidationError = (field: string) => {
    return (
      <span className={`${defaultClass}__address-field-validation`}>
        <Icon icon="warning-sign" intent="danger" iconSize={16} />
        {` ${field} ${t("pages.billing.addressFieldValidation")}!`}
      </span>
    );
  };

  const ChangeBillingAddress = (
    <>
      <PageSection>
        <Row>
          <Col sm={12} md={12}>
            <Card className="billing-ad-container">
              <Row id="1">
                <Col sm={11} md={11}>
                  <p className={classNames(`${defaultClass}__address-title`)}>
                    {t("pages.billing.changeBillingAddress")}
                  </p>
                </Col>
                <Col sm={1} md={1}>
                  <Button
                    icon="cross"
                    minimal={true}
                    onClick={() =>
                      setCurrentSection(BillingPageSection.Billing)
                    }
                  ></Button>
                </Col>
              </Row>
              <div
                className={classNames(`${defaultClass}__address-page-break`)}
              />
              <form onSubmit={handleSubmit(handleUpdateBillingAddress)}>
                <Row
                  id="2"
                  className={classNames(`${defaultClass}__address-field-row`)}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {`${t("pages.billing.organization")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      className={classNames(
                        !!errors.company && "bp3-intent-danger"
                      )}
                      placeholder={t("pages.billing.address.companyName")}
                      name="company"
                      defaultValue={user?.organization?.billingAddress?.company}
                      inputRef={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                    {!!errors.company &&
                      billingValidationError(
                        t("pages.billing.address.companyName")
                      )}
                  </Col>
                </Row>

                <Row>
                <Col sm={12} md={6}
                  id="3"
                  className={classNames(`${defaultClass}__address-field-row user-fname`)}
                >
                  <Col xs={12} md={6} className="sub-title">
                    {`${t("pages.billing.firstName")}*`}
                  </Col>
                  <Col xs={12} md={12}>
                    <InputGroup
                      className={classNames(
                        !!errors.firstName && "bp3-intent-danger"
                      )}
                      placeholder={t("pages.billing.address.firstName")}
                      defaultValue={
                        user?.organization?.billingAddress?.firstName
                      }
                      name="firstName"
                      inputRef={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                    {!!errors.firstName &&
                      billingValidationError(
                        t("pages.billing.address.firstName")
                      )}
                  </Col>
                </Col>

                <Col sm={12} md={6}
                  id="11"
                  className={classNames(`${defaultClass}__address-field-row user-lname`)}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {`${t("pages.billing.lastName")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      placeholder={t("pages.billing.address.lastName")}
                      defaultValue={
                        user?.organization?.billingAddress?.lastName
                      }
                      name="lastName"
                      inputRef={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                    {!!errors.lastName &&
                      billingValidationError(
                        t("pages.billing.address.lastName")
                      )}
                  </Col>
                </Col>
                </Row>

                <Row>
                <Col sm={12} md={6}
                  id="4"
                  className={classNames(`${defaultClass}__address-field-row user-fname`)}
                >
                  <Col sm={12} md={6} className="sub-title">
                    {`${t("pages.billing.addressLine1")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      className={classNames(
                        !!errors.line1 && "bp3-intent-danger"
                      )}
                      placeholder={t("pages.billing.address.line1")}
                      defaultValue={user?.organization?.billingAddress?.line1}
                      name="line1"
                      inputRef={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                    {!!errors.line1 &&
                      billingValidationError(t("pages.billing.address.line1"))}
                  </Col>
                </Col>

                <Col sm={12} md={6}
                  id="5"
                  className={classNames(`${defaultClass}__address-field-row user-lname`)}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {t("pages.billing.addressLine2")}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      placeholder={t("pages.billing.address.line2")}
                      defaultValue={user?.organization?.billingAddress?.line2}
                      name="line2"
                      inputRef={register({
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                  </Col>
                </Col>
                </Row>

                <Row>
                <Col sm={12} md={6}
                  id="6"
                  className={classNames(`${defaultClass}__address-field-row user-fname`)}
                >
                  <Col sm={12} md={6} className="sub-title">
                    {`${t("pages.billing.cityLabel")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      className={classNames(
                        !!errors.city && "bp3-intent-danger"
                      )}
                      placeholder={t("pages.billing.address.city")}
                      defaultValue={user?.organization?.billingAddress?.city}
                      name="city"
                      inputRef={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                    {!!errors.city &&
                      billingValidationError(t("pages.billing.address.city"))}
                  </Col>
                </Col>

                <Col sm={12} md={6}
                  id="7"
                  className={classNames(`${defaultClass}__address-field-row user-lname`)}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {`${t("pages.billing.postcodeLabel")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      className={classNames(
                        !!errors.zipCode && "bp3-intent-danger"
                      )}
                      placeholder={t("pages.billing.address.zipCode")}
                      defaultValue={user?.organization?.billingAddress?.zipCode}
                      name="zipCode"
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {!!errors.zipCode &&
                      billingValidationError(
                        t("pages.billing.address.zipCode")
                      )}
                  </Col>
                </Col>
                </Row>

                <Row
                  id="8"
                  className={classNames(`${defaultClass}__address-field-row`)}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {`${t("pages.billing.countryLabel")}*`}
                  </Col>
                  <Col sm={12} md={12}>
                    <SelectInput
                      items={countries}
                      onChange={setSelectedCountry}
                      value={selectedCountry}
                      filterable={true}
                      placeholder={t("pages.billing.countryLabel")}
                    />
                  </Col>
                  {!!errors.country &&
                    billingValidationError(t("pages.billing.countryLabel"))}
                </Row>

                <Row
                  id="9"
                  className={classNames(
                    `${defaultClass}__address-field-contact-details`
                  )}
                >
                  <Col sm={12} md={12} className="sub-title">
                    {t("pages.billing.contactNumber")}
                  </Col>
                  <Col sm={12} md={12}>
                    <InputGroup
                      placeholder={t("pages.billing.address.contactNumber")}
                      defaultValue={user?.organization?.billingAddress?.phone}
                      name="phone"
                      inputRef={register({
                        pattern: /^[a-zA-Z0-9\s,'-/]*$/,
                      })}
                    />
                  </Col>
                </Row>

                <Row
                  id="10"
                  className={classNames(`${defaultClass}__address-field-row`)}
                >
                  <Col sm={12} md={12} />
                  <Col sm={12} md={1}>
                    <Button className="save-btn" fill={true} intent={Intent.PRIMARY} type="submit">
                      {t("save")}
                    </Button>
                  </Col>
                  <Col sm={12} md={2}>
                    <Button className="cancel-btn bp3-intent-secondary"
                      fill={true}
                      onClick={handleUpdateAddressCancelButtonClick}
                    >
                      {t("cancel")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>
      </PageSection>
    </>
  );
  const cancellationSectionHeader = (
    <PageSection>
      <h2>{t("pages.billing.planCancellation")}</h2>
      <Callout intent={Intent.WARNING}>
        {t("pages.billing.planCancellationWarning", {
          planType: user?.organization?.planName || "",
          organizationName: user?.organization?.name || "",
        })}
      </Callout>
    </PageSection>
  );

  const cancelStepOneSection = (
    <>
      {cancellationSectionHeader}
      <PageSection>
        <h2>{t("pages.billing.cancelSectionOne.question")}</h2>
        <RatingCard
          className="px-5"
          value={cancelAnswers.questionOne}
          name="cancellation-rating"
          startValue={1}
          endValue={10}
          onChange={(value) =>
            setCancelAnswers({ ...cancelAnswers, questionOne: value })
          }
          startText={t("pages.billing.cancelSectionOne.notLikely")}
          endText={t("pages.billing.cancelSectionOne.extremelyLikely")}
        />
      </PageSection>

      <PageSection>
        <div className="text-center">
          <a href="#" onClick={handleDoNotCancelClick}>
            {t("pages.billing.doNotCancel")}
          </a>
          <Button
            className="ml-3"
            intent={Intent.PRIMARY}
            onClick={handleCancelStepTwoClick}
          >
            {t("next")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const cancelStepTwoSection = (
    <>
      {cancellationSectionHeader}
      <PageSection>
        <h2>{t("pages.billing.cancelSectionTwo.question")}</h2>
        <RadioGroup
          onChange={(event) =>
            setCancelAnswers({
              ...cancelAnswers,
              questionTwoMain: event?.currentTarget.value,
            })
          }
          selectedValue={cancelAnswers.questionTwoMain}
        >
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerOne")}
            value="1"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerTwo")}
            value="2"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerThree")}
            value="3"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerFour")}
            value="4"
            className={Classes.CARD}
          />
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerFive")}
            value="5"
            className={Classes.CARD}
          >
            <div className="bp3-radio-description">
              <TextArea
                rows={4}
                fill={true}
                placeholder={t(
                  "pages.billing.cancelSectionTwo.answerFiveDetailLabel"
                )}
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionTwoSub: event?.currentTarget.value,
                  })
                }
              />
            </div>
          </Radio>
          <Radio
            label={t("pages.billing.cancelSectionTwo.answerSix")}
            value="6"
            className={Classes.CARD}
          >
            <div className="bp3-radio-description">
              <TextArea
                rows={4}
                fill={true}
                placeholder={t(
                  "pages.billing.cancelSectionTwo.answerSixDetailLabel"
                )}
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionThreeSub: event?.currentTarget.value,
                  })
                }
              />
            </div>
          </Radio>
        </RadioGroup>
      </PageSection>

      <PageSection>
        <div className="text-center">
          <a href="#" onClick={handleDoNotCancelClick}>
            {t("pages.billing.doNotCancel")}
          </a>
          <Button
            className="ml-3"
            intent={Intent.PRIMARY}
            onClick={handleCancelStepThreeClick}
          >
            {t("next")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const cancelStepThreeSection = (
    <>
      {cancellationSectionHeader}
      <PageSection>
        <h2>{t("pages.billing.cancelSectionThree.question")}</h2>
        <RadioGroup
          onChange={(event) =>
            setCancelAnswers({
              ...cancelAnswers,
              questionThreeMain: event?.currentTarget.value,
            })
          }
          selectedValue={cancelAnswers.questionThreeMain}
        >
          <Radio label={t("yes")} value="yes" className={Classes.CARD}>
            <div className="bp3-radio-description">
              <RadioGroup
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionThreeSub: event?.currentTarget.value,
                  })
                }
                selectedValue={cancelAnswers.questionThreeSub}
              >
                <Radio
                  label={t("pages.billing.cancelSectionThree.lucidChart")}
                  value="lucidChart"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.drawIo")}
                  value="drawIo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.smartDraw")}
                  value="smartDraw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.cacoo")}
                  value="cacoo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.other")}
                  value="other"
                />
              </RadioGroup>
            </div>
          </Radio>
          <Radio label={t("no")} value="no" className={Classes.CARD} />
          <Radio
            label={t("pages.billing.cancelSectionThree.maybe")}
            value="maybe"
            className={Classes.CARD}
          />
        </RadioGroup>
      </PageSection>
      <PageSection>
        <div className="text-center">
          <a href="#" onClick={handleDoNotCancelClick}>
            {t("pages.billing.doNotCancel")}
          </a>
          <Button
            className="ml-3"
            intent={Intent.DANGER}
            loading={isPlanCancelLoading}
            onClick={handleFinalPlanCancelClick}
          >
            {t("pages.billing.cancelPlan")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const AddBillingContacts = (
    <>
      <UserFilterInput
        navigator={setCurrentSection}
        currentBillingContacts={user?.organization?.billingContacts}
      />
    </>
  );

  const billingInfoInvoices = (
    <>
      <PageSection>
        <PageTitle>{t("pages.billing.billingPageTitle")}</PageTitle>
      </PageSection>
      <Tabs
        animate={true}
        id="tabsSwitcher"
        renderActiveTabPanelOnly={false}
        vertical={false}
      >
        <Tab
          id="billing"
          title="Billing Details"
          panel={<BillingDetails setCurrentSection={setCurrentSection} openPaymentSources={props.openPaymentSources}  />}
        />
        <Tab
          id="invoices"
          title="Invoices"
          onClickCapture={handleInvoicesTabClick}
          panel={<InvoicesPage />}
        />
      </Tabs>
    </>
  );

  const planCancelDialog = (
    <Modal
      className={`${defaultClass}__plan-cancel-dialog`}
      icon={IconNames.ERROR}
      onClose={() => {}}
      isOpen={isPlanCancelDialogOpen}
      button1Text={showPausePlanButton() ? t("pages.billing.pause") : undefined}
      button1Action={handleDialogPlanPauseClick}
      button2Text={t("pages.billing.cancelPlan")}
      button2Intent={Intent.DANGER}
      button2Action={handleDialogPlanCancelClick}
      button3Text={t("pages.billing.doNothing")}
      button3Intent={Intent.PRIMARY}
      button3Action={handleDialogCloseClick}
    >
      {t("pages.billing.cancelAlertDescription")}
    </Modal>
  );

  return (
    <>
      <div className={defaultClass}>
        <Grid fluid>{geCurrentSection()}</Grid>
      </div>
      {planCancelDialog}
    </>
  );
};

interface IBillingDetailsProps extends IComponentProps {
  setCurrentSection: (value: BillingPageSection) => void;
  openPaymentSources: () => void;
}

const BillingDetails: React.FunctionComponent<IBillingDetailsProps> = (
  props
) => {
  const defaultClass = "billing-page";

  const { user }: { user: IUser; userLoading: boolean } = useValues(userLogic);

  const { closeIfEmbedded, track } = useActions(appLogic);

  const { t } = useTranslation();

  const getPaymentSourceImage = (paymentDetails: any) => {
    if (paymentDetails?.type === "paypal_express_checkout") {
      return "resources/images/payment-methods/paypal.svg";
    } else if (paymentDetails?.type === "card") {
      return `resources/images/payment-methods/${paymentDetails?.cardType}.svg`;
    }
    return "https://via.placeholder.com/40x30text=nothing";
  };

  const getPlanName = () => {
    return user?.planName || user?.organization?.planName;
  };

  const showChangePlanButton = () => {
    return (
      !user?.organization?.subscription?.cancelled &&
      !user?.organization?.subscription?.cancelScheduled
    );
  };

  const [companyOwner, setCompanyOwner] = useState<any>();

  const [billingContactsCount, setBillingContactsCount] = useState<number>(0);

  useEffect(() => setCompanyOwner(user?.organization?.billingOwner), [user]);

  useEffect(() => {
    setBillingContactsCount(user?.organization?.billingContacts?.length);
  }, [user]);

  const getBillingPeriod = () => {
    const billingPeriod = user.organization?.subscription?.billingPeriodUnit;
    if (billingPeriod === "year") {
      return t("pages.billing.planAnnually");
    } else if (billingPeriod === "month") {
      return t("pages.billing.planMonthly");
    } else {
      return "";
    }
  };

  // get the billing contacts
  const [billinContacts, setBillinContacts] = useState<IBillingContact[]>([]);
  const getBillingContacts = () => {
    let billingCs: IBillingContact[] = [];
    const currentuser = user.email;
    user.organization?.billingContacts?.forEach((item: any) => {
      billingCs.push({
        email: item.email,
        name: item.label,
        thumbnail: item?.avatar,
        isYou: currentuser === item.email ? true : false,
        role: item?.role,
      });
    });
    setBillinContacts(billingCs);
  };
  useEffect(getBillingContacts, [user]);

  const [
    isEnterpriseOwnerDetaulsPopUpOpen,
    setIsEnterpriseOwnerDetaulsPopUpOpen,
  ] = useState<boolean>(false);

  const handlePlanUpgradeClick = () => {
    track("left.account.billing.upgrade.click");
    openPlansPage();
    closeIfEmbedded();
  };

  const toggleIsEnterpriseOwnerDetaulsPopUpOpen = () => {
    setIsEnterpriseOwnerDetaulsPopUpOpen(!isEnterpriseOwnerDetaulsPopUpOpen);
  };

  const openPlansPage = () => {
    window.open(`${process.env.WEBSITE_URL}/plans/`, "_blank");
  }

  const handlePlanChangeClick = () => {
    if (user?.planType === Plan.Enterprise) {
      toggleIsEnterpriseOwnerDetaulsPopUpOpen();
      track("accountPortal.billing.changePlan.click");
    } else {
      openPlansPage();
      closeIfEmbedded();
      track("left.account.billing.change.click");
    }
  };

  // this function returns last 8 digits of a given card number
  const getMaskedCardNumber = (cardNumber: string) => {
    const length: number = cardNumber.length;
    return cardNumber.substring(length - 8, length);
  };

  const handleAddNewBillingContactBtnClick = () => {
    props.setCurrentSection(BillingPageSection.AddBillingContact);
    track("accountPortal.billing.newContact.click");
  };

  const handleChangePaymentMethodButtonClick = () => {
    props.openPaymentSources();
    track("accountPortal.billing.paymentChange.click");
  };

  const handleUpdateAddressButtonClick = () => {
    track("accountPortal.billing.paymentChange.click");
    props.setCurrentSection(BillingPageSection.ChangeBillingAddress);
  };

  const billingSection = (
    <>
      <Permission permission={PermissionType.CanViewPlan}>
        {user?.organization?.subscription?.cancelled && (
          <PageSection>
            <Callout intent={Intent.SUCCESS} icon={null}>
              {t("pages.billing.downgradedToFree")}
            </Callout>
          </PageSection>
        )}

        <PageSection>
          <Card className="billing-pg">
            <Row>
              <Col xs={12} md={12}>
                <p className={classNames(`${defaultClass}__section-heading`)}>
                  {t("pages.billing.planDetails")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex">
                <div
                  className={classNames(`${defaultClass}__plan-details-border`)}
                >
                  <img className="billing-img" src="resources/images/enterprise-icon.png" />
                  <div
                    className={classNames(
                      `${defaultClass}__plan-details-plan-details`
                    )}
                  >
                    <span
                      className={classNames(`${defaultClass}__plan-title`)}
                    >{`${getPlanName()} `}</span>
                    {user?.organization?.size != -1 
                      ? (
                          <span
                            className={classNames(`${defaultClass}__plan-seats`)}
                          >
                            ({user?.organization?.size -
                              user?.organization?.remainingSeats}
                            /{user?.organization?.size} {t("pages.billing.users")})
                          </span>
                        )
                      : (
                          <span
                            className={classNames(`${defaultClass}__plan-seats`)}
                          >
                            ({user?.organization?.usedAccount} {t("pages.billing.users")})
                          </span>
                        )}
                    {user?.organization?.subscription?.paused && (
                      <span className="text-primary ml-1">
                        ({t("pages.billing.paused")})
                      </span>
                    )}
                    {user?.organization?.subscription?.pauseScheduled && (
                      <span className="text-primary ml-1">
                        ({t("pages.billing.pauseScheduled")})
                      </span>
                    )}
                  </div>
                </div>
                <Button 
                  className={classNames(
                    `${defaultClass}__plan-details-change-plan-btn-div bp3-button bp3-intent-secondary`
                  )}
                >
                  <Permission permission={PermissionType.CanChangePlan}>
                    {showChangePlanButton() && (
                      <a
                        className={classNames(
                          `${defaultClass}__plan-details-change-plan-btn`
                        )}
                        onClick={handlePlanChangeClick}
                      >
                        {t("changePlan")}
                      </a>
                    )}
                  </Permission>
                  <Permission permission={PermissionType.CanBuyNewPlan}>
                    <Button
                      intent={Intent.PRIMARY}
                      onClick={handlePlanUpgradeClick}
                    >
                      {t("pages.billing.upgrade")}
                    </Button>
                  </Permission>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <span
                  className={classNames(
                    `${defaultClass}__plan-details-sub-title`
                  )}
                >
                  {getBillingPeriod && (
                    <span className="billing-date">
                      {t("pages.billing.billed")} {getBillingPeriod()},{" "}
                    </span>
                  )}
                  {user?.organization?.subscription?.currentTermEndDate && (
                    <span className="billing-date">
                      {t("pages.billing.nextBillingDate")}{" "}
                      {formatNextBillingDate(
                        user.organization.subscription.currentTermEndDate
                      )}
                    </span>
                  )}
                </span>
              </Col>
            </Row>
            {/* FIXME - Hot Fix for haardcoded admin contact if some need to contant*/}
            {isEnterpriseOwnerDetaulsPopUpOpen && (
              <OwnerDetailsPopup
                closePopUp={toggleIsEnterpriseOwnerDetaulsPopUpOpen}
                firstname={"Oshala"}
                lastName={"Warz"}
                email={"oshala@creately.com"}
                thumbnail={companyOwner?.avatar}
              />
            )}
          </Card>
        </PageSection>
      </Permission>

      <Permission
        permission={[
          PermissionType.CanViewBillingContacts,
          PermissionType.CanAddBillingContact,
        ]}
      >
        <PageSection>
          <Card className="billing-pg">
            <Row>
              <Col xs={12} md={6}>
                <p className={classNames(`${defaultClass}__section-heading`)}>
                  {t("pages.billing.billingContacts")}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <Permission permission={PermissionType.CanAddBillingContact}>
                  <Button
                    icon="plus"
                    className={classNames(
                      "bp3-intent-secondary",
                      `${defaultClass}__billing-contacts-add-btn`
                    )}
                    onClick={handleAddNewBillingContactBtnClick}
                  >
                    {t("pages.billing.addBillingContactBtn")}
                  </Button>
                </Permission>
              </Col>
            </Row>
            <Row>
              <Permission permission={PermissionType.CanViewBillingContacts}>
                <Col xs={12} md={12}>
                  <p className="billing-page__plan-title">
                    {user?.organization?.name
                      ? user.organization.name
                      : user?.firstName}
                    {t("pages.billing.billingContactSubTitle")}
                  </p>
                </Col>
                {!!billinContacts.length &&
                  billinContacts.map((u) => (
                    <Col xs={12} md={12} key={u.email}>
                      <BillingContactItem
                        email={u.email}
                        label={u.name}
                        isYou={u.isYou}
                        thumbnail={u.thumbnail}
                        role={u?.role}
                        billingContactsCount={billingContactsCount}
                        currentUserRole={user?.role}
                      />
                    </Col>
                  ))}
              </Permission>
            </Row>
          </Card>
        </PageSection>
      </Permission>

      <PageSection>
        <Permission
          permission={[
            PermissionType.CanViewPaymentMethod,
            PermissionType.CanChangePaymentMethod,
          ]}
        >
          <Card className="billing-pg">
            <Row>
              <Col xs={12} md={6}>
                <p className={classNames(`${defaultClass}__section-heading`)}>
                  {t("pages.billing.paymentMethod")}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <Permission permission={PermissionType.CanChangePaymentMethod}>
                  <Button
                    className={classNames(
                      "bp3-intent-secondary",
                      `${defaultClass}__billing-method-change-btn`
                    )}
                    onClick={handleChangePaymentMethodButtonClick}
                  >
                    {t("pages.billing.changePaymentMethodBtn")}
                  </Button>
                </Permission>
              </Col>
            </Row>
            <Row className={classNames(`${defaultClass}__card-container`)}>
              <Permission permission={PermissionType.CanViewPaymentMethod}>
                <Col md={2} sm={12}>
                  <img
                    className={classNames(
                      `${defaultClass}__billing-method-type-thumbnail`
                    )}
                    src={getPaymentSourceImage(
                      user?.organization?.paymentDetails
                    )}
                  ></img>
                </Col>
                <Col md={2} sm={12} className={classNames(`${defaultClass}__plan-title plan-title`)}>
                  <span className="payment-method">
                    {user?.organization?.paymentDetails?.maskedCardNumber
                      ? getMaskedCardNumber(
                          user?.organization?.paymentDetails?.maskedCardNumber
                        )
                      : ""}
                  </span>
                </Col>
                <Col md={2} sm={12} className="payment-method">
                  <Tag
                    className={classNames(
                      `${defaultClass}__billing-method-is-primary`
                    )}
                  >
                    {t("pages.billing.primary")}
                  </Tag>
                </Col>
              </Permission>
            </Row>
            <Row>
              <Col md={12} sm={12} className="payment-date">
                  {user?.organization?.subscription?.currentTermStartDate && (
                    <span
                      className={classNames(
                        `${defaultClass}__billing-method-last-updated`
                      )}
                    >
                      {`${t("pages.billing.lastUsedOn")} `}
                      {formatNextBillingDate(
                        user?.organization?.subscription?.currentTermStartDate
                      )}
                    </span>
                  )}
                </Col>
            </Row>
          </Card>
        </Permission>
      </PageSection>

      <Permission
        permission={[
          PermissionType.CanViewBillingAddress,
          PermissionType.CanEditBillingAddress,
        ]}
      >
        <PageSection>
          <Card className="billing-pg">
            <Row>
              <Col xs={12} md={6}>
                <p className={classNames(`${defaultClass}__section-heading`)}>
                  {t("pages.billing.billingAddress")}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <Permission permission={PermissionType.CanEditBillingAddress}>
                  <Button
                    className={classNames("bp3-intent-secondary", `${defaultClass}__change-btn`)}
                    onClick={() => handleUpdateAddressButtonClick()}
                  >
                    {t("pages.billing.changeBillingAddressBtn")}
                  </Button>
                </Permission>
              </Col>
            </Row>
            <Row className={classNames(`${defaultClass}__card-container`)}>
              <Permission permission={PermissionType.CanViewBillingAddress}>
                {user?.organization?.billingAddress && (
                  <AddressView address={user.organization.billingAddress} />
                )}
              </Permission>
            </Row>
          </Card>
        </PageSection>
      </Permission>
    </>
  );

  return (
    <>
      <div className={defaultClass}>
        <Grid fluid>{billingSection}</Grid>
      </div>
    </>
  );
};

const InvoicesPage: React.FunctionComponent = () => {
  const { user }: { user: IUser; userLoading: boolean } = useValues(userLogic);
  return (
    <>
      <BillingInvoicesTable data={user?.organization?.invoices} />
    </>
  );
};

/**
 * This component returns the billing address view.
 * @param props Billing data
 */
const AddressView: React.FC<{ address: any }> = (props: any) => {
  const defaultClass = "billing-page";
  const { t } = useTranslation();
  const address = props.address;
  return (
    <>
      {address && (
        <Col xs={12} md={6}>
          {address?.firstName && address?.lastName && address && (
            <div
              className={classNames(
                `${defaultClass}__billing-address-address-title`
              )}
            >
              {address?.firstName} {address?.lastName}, {address?.company}
            </div>
          )}
          {address && <div>{address?.line1}</div>}
          {address?.line2 && <div>{address?.line2}</div>}
          {address?.city && address?.zipCode && (
            <div>
              {address?.city} {address?.zipCode}
            </div>
          )}
          {address?.country && <div>{getName(address?.country)}</div>}
          {address?.phone && (
            <div>
              {t("pages.billing.contactNumber")} : {address?.phone}
            </div>
          )}
        </Col>
      )}
    </>
  );
};
