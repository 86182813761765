import { kea } from "kea";
import { router } from "kea-router";

export enum PageRoute {
  Overview = "/overview",
  Organization = "/organization",
  Users = "/users",
  Account = "/account",
  Billing = "/billing",
  Close = "/close",
  Manage = "/manage",
  Setting = "/setting",
}

export const routeLogic = kea({
  connect: {
    values: [router, ["location"]],
    actions: [router, ["push"]],
  },

  actions: () => ({
    overviewPage: true,
    accountPage: true,
    settingPage: true,
    organizationPage: true,
    usersPage : true,
    billingPage: true,
    closePage: true,
    managePage: true,
    setMainSection: (value: any) => ({ value }),
    reset: false,
  }),
  reducers: () => ({
    closePageMainSection: [
      false,
      {
        setMainSection: (_: any, { value }: any) => value,
        reset: () => false,
      },
    ],
  }),

  actionToUrl: () => ({
    overviewPage: () => PageRoute.Overview,
    accountPage: () => PageRoute.Account,
    settingPage: () => PageRoute.Setting,
    organizationPage: () => PageRoute.Organization,
    usersPage: () => PageRoute.Users,
    billingPage: () => PageRoute.Billing,
    closePage: () => PageRoute.Close,
    managePage: () => PageRoute.Manage,
  }),

  urlToAction: ({ actions }: any) => ({
    [PageRoute.Overview]: () => actions.overviewPage(),
    [PageRoute.Account]: () => actions.accountPage(),
    [PageRoute.Setting]: () => actions.settingPage(),
    [PageRoute.Organization]: () => actions.organizationPage(),
    [PageRoute.Users]: () => actions.usersPage(),
    [PageRoute.Billing]: () => actions.billingPage(),
    [PageRoute.Close]: () => actions.closePage(),
    [PageRoute.Manage]: () => actions.managePage(),
  }),

  listeners: ({ values, actions }: any) => ({
    overviewPage: () => {
      if (values.location.pathname !== PageRoute.Overview) {
        actions.push(PageRoute.Overview);
      }
    },
    accountPage: () => {
      if (values.location.pathname !== PageRoute.Account) {
        actions.push(PageRoute.Account);
      }
    },
    settingPage: () => {
      if (values.location.pathname !== PageRoute.Setting) {
        actions.push(PageRoute.Setting);
      }
    },
    organizationPage: () => {
      if (values.location.pathname !== PageRoute.Organization) {
        actions.push(PageRoute.Organization);
      }
    },
    usersPage: () => {
      if (values.location.pathname !== PageRoute.Users) {
        actions.push(PageRoute.Users);
      }
    },
    billingPage: () => {
      if (values.location.pathname !== PageRoute.Billing) {
        actions.push(PageRoute.Billing);
      }
    },
    closePage: () => {
      if (values.location.pathname !== PageRoute.Close) {
        actions.push(PageRoute.Close);
      }
    },
    managePage: () => {
      if (values.location.pathname !== PageRoute.Manage) {
        actions.push(PageRoute.Manage);
      }
    },
  }),
});
